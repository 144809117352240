import React, { useRef, useEffect } from 'react';
import './animated-text.scss';

const AnimatedText = ({ children }) => {
    const textRef = useRef(null);

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const handleIntersection = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('fade-in');
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);

        const textElement = textRef.current;

        if (textElement) {
            observer.observe(textElement);
        }

        return () => {
            if (textElement) {
                observer.unobserve(textElement);
            }
        };
    }, []);

    return <div ref={textRef} className="animated-text">{children}</div>;
};

export default AnimatedText;
