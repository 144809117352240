import SolutionAccordion from "../solution-feature-accordion/solution-accordion";
import SolutionVideo from "../solution-video/solution-video";
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./automated-documentation.scss";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AnimatedText from "../animated-text/animated-text";

export default function AutomatedDocumentation() {

    const AutomatedDocumentation = {
        featureTitle: 'Automated Documentation',
        intelligentPhysicalLayer: '/solutions/automated-documentation-assets/physical-layer-intelligence.svg',
        whiteDownloadIcon: '/products/white-download-icon.svg',
        solutionBrief: '/glc-resources/solutionbriefs/Automated Documentation.pdf',
        videoSection: {
            divider: '/solutions/datacenter-colocation-assets/divider.svg',
            icon: '/solutions/automated-documentation-assets/intelligent-icon.png',
            divider1: '/solutions/datacenter-colocation-assets/divider1.svg',
            solutionDiagram: '/solutions/automated-documentation-assets/how-it-works-diagram.png',
            diagramCaption: 'Increased efficiency and reduced risk through automated network documentation.',
            titleSolid1: 'Intelligent',
            titleStroke: 'Physical Layer',
            titleSolid: ''
        },
        features: [
            {
                icon: '/solutions/automated-documentation-assets/feature-1.svg',
                title: 'Agile Networking<br/> Enabled',
            },
            {
                icon: '/solutions/automated-documentation-assets/feature-2.svg',
                title: 'Tracks MACs in<br/> Real-time',
            },
            {
                icon: '/solutions/automated-documentation-assets/feature-3.svg',
                title: 'Software Controlled<br/> Management of<br/> Physical Layer',
            },
            {
                icon: '/solutions/automated-documentation-assets/feature-4.svg',
                title: 'Minimal Human<br/> (Middle-man)<br/> Intervention',
            },
        ],
        accordionFeatures: [
            {
                image: '/solutions/automated-documentation-assets/network-topology.png',
                titleGreenPart: 'Network',
                titleWhitePart: 'Topology',
                information: 'The NOC can remotely blink all the LEDs to guide the technician to the device they need to work on, when you utilize the Data Center Colocation solution to guide moves, additions, and changes (MACs). By doing this, your NOC can help navigate the data center using a remote hand.'
            },
            {
                image: '/solutions/automated-documentation-assets/alarms.png',
                titleGreenPart: 'Alarms',
                titleWhitePart: '',
                information: 'The process of troubleshooting and fixing issues is sped up and made simpler by the use of automatic documentation in conjunction with alerts to inform network operators of unauthorized changes including fiber disconnections, power supply failures, and unauthorized repatching. What would have been a lengthy outage or security breach is now a rapid remedy.'
            },
            {
                image: '/solutions/automated-documentation-assets/audit-trials.png',
                titleGreenPart: 'Audit',
                titleWhitePart: 'Trails',
                information: 'Audit trails log real-time information of the network which includes the current state of the network as well as the timing and location of all previous modifications. This makes sure the data center network is protected from any security threat.'
            },
            {
                image: '/solutions/automated-documentation-assets/testing-documentation.png',
                titleGreenPart: 'Testing',
                titleWhitePart: 'Documentation',
                information: 'APD offers a text version of the network documentation in addition to a visual representation of the topology. The port-to-port connections and all Green Lambda devices are highlighted in this version. This text-based network documentation gives executives and managers access to a high-level picture of their data center investments and equipment, which strengthens their capacity to make wise decisions.'
            },

        ]
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Automated Documentation | Green Lambda Corporation</title>
                    <meta name="description" content="Automated documentation for real-time tracking of MACs, network topology information, alarm logs, and audit trails for efficient physical layer management." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="automated-documentation-wrapper">
                {/* Datacenter Colocation Hero Section Start */}
                <div className="hero position-relative">
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="hero-content bg-blue d-flex justify-content-center align-items-center flex-column position-relative">
                        <div className="hero-top-left-gradient"></div>
                        <div className="hero-heading-bg">
                            <svg className="w-100 h-auto" id="hero-bg-vector" width="1495" height="588" viewBox="0 0 1495 588" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <linearGradient id="gradient-1" x1="0" y1="0" x2="1" y2="1">
                                        <stop offset="0%" stop-color="#48B84B" stop-opacity="0" />
                                        <stop offset="40%" stop-color="#48B84B" stop-opacity="1">
                                            <animate attributeName="stop-opacity" values="0;1;0" dur="1.5s" repeatCount="1" begin="0s" />
                                        </stop>
                                        <stop offset="60%" stop-color="#123C4D" stop-opacity="1">
                                            <animate attributeName="stop-opacity" values="1;0;1" dur="1.5s" repeatCount="1" begin="1.5s" />
                                        </stop>
                                        <stop offset="100%" stop-color="#123C4D" stop-opacity="0" />
                                    </linearGradient>
                                    <linearGradient id="gradient-3" x1="707.5" y1="570.5" x2="412.5" y2="234.5" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#4BD04A" stop-opacity="0" />
                                        <stop offset="40%" stop-color="#4BD04A" stop-opacity="0">
                                            <animate attributeName="stop-opacity" values="0;1;0" dur="1.5s" repeatCount="1" begin="1s" />
                                        </stop>
                                        <stop offset="60%" stop-color="#123C4D" stop-opacity="0">
                                            <animate attributeName="stop-opacity" values="1;0;1" dur="1.5s" repeatCount="1" begin="2.5s" />
                                        </stop>
                                        <stop offset="100%" stop-color="#123C4D" stop-opacity="0" />
                                    </linearGradient>
                                    <linearGradient id="gradient-2" x1="770" y1="25.5" x2="1071.5" y2="294" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#4BD04A" stop-opacity="0" />
                                        <stop offset="40%" stop-color="#4BD04A" stop-opacity="0">
                                            <animate attributeName="stop-opacity" values="0;1;0" dur="1.5s" repeatCount="1" begin="2s" />
                                        </stop>
                                        <stop offset="60%" stop-color="#123C4D" stop-opacity="0">
                                            <animate attributeName="stop-opacity" values="0;1;0" dur="1.5s" repeatCount="1" begin="3.5s" />
                                        </stop>
                                        <stop offset="100%" stop-color="#123C4D" stop-opacity="0" />
                                    </linearGradient>
                                    <linearGradient id="gradient-4" x1="1477.5" y1="570.5" x2="1182.5" y2="234.5" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#4BD04A" stop-opacity="0" />
                                        <stop offset="40%" stop-color="#4BD04A" stop-opacity="0">
                                            <animate attributeName="stop-opacity" values="0;1;0" dur="1.5s" repeatCount="1" begin="3s" />
                                        </stop>
                                        <stop offset="60%" stop-color="#123C4D" stop-opacity="0">
                                            <animate attributeName="stop-opacity" values="1;0;1" dur="1.5s" repeatCount="1" begin="4.5s" />
                                        </stop>
                                        <stop offset="100%" stop-color="#123C4D" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                                <path d="M298.64 0H39.29C17.59 0 0 17.59 0 39.29V548.49C0 570.19 17.59 587.78 39.29 587.78H298.64C320.35 587.78 337.94 570.19 337.94 548.49V39.29C337.94 17.59 320.35 0 298.64 0ZM328 547.18C328 564.75 313.76 578.99 296.19 578.99H41.75C24.18 578.99 9.94 564.75 9.94 547.18V40.61C9.94 23.04 24.18 8.79 41.75 8.79H296.19C313.76 8.79 328 23.04 328 40.61V547.18Z" fill="url(#gradient-1)">
                                    <animate attributeName="fill" values="#123C4D;#123C4D" dur="1s" repeatCount="indefinite" begin="1.5s" />
                                </path>
                                <path opacity="0.36" d="M30.5508 31.5303V557.95H307.391V31.5303H30.5508ZM297.701 549.17H40.2408V40.3003H297.701V549.17Z" fill="#123C4D" />
                                <path opacity="0.36" d="M112.47 70.2598H74.0101C71.5701 70.2598 69.5801 72.2398 69.5801 74.6798V113.15C69.5801 115.59 71.5701 117.57 74.0101 117.57H112.47C114.92 117.57 116.9 115.59 116.9 113.15V74.6798C116.9 72.2398 114.92 70.2598 112.47 70.2598ZM108.74 109.41H77.7401V78.4198H108.74V109.41Z" fill="#123C4D" />
                                <path opacity="0.36" d="M263.62 70.2598H225.16C222.72 70.2598 220.73 72.2398 220.73 74.6798V113.15C220.73 115.59 222.72 117.57 225.16 117.57H263.62C266.07 117.57 268.05 115.59 268.05 113.15V74.6798C268.05 72.2398 266.07 70.2598 263.62 70.2598ZM259.89 109.41H228.89V78.4198H259.89V109.41Z" fill="#123C4D" />
                                <path opacity="0.36" d="M112.47 140.47H74.0101C71.5701 140.47 69.5801 142.45 69.5801 144.89V183.36C69.5801 185.8 71.5701 187.78 74.0101 187.78H112.47C114.92 187.78 116.9 185.8 116.9 183.36V144.89C116.9 142.45 114.92 140.47 112.47 140.47ZM108.74 179.62H77.7401V148.63H108.74V179.62Z" fill="#123C4D" />
                                <path opacity="0.36" d="M263.62 140.47H225.16C222.72 140.47 220.73 142.45 220.73 144.89V183.36C220.73 185.8 222.72 187.78 225.16 187.78H263.62C266.07 187.78 268.05 185.8 268.05 183.36V144.89C268.05 142.45 266.07 140.47 263.62 140.47ZM259.89 179.62H228.89V148.63H259.89V179.62Z" fill="#123C4D" />
                                <path opacity="0.36" d="M112.47 210.68H74.0101C71.5701 210.68 69.5801 212.66 69.5801 215.1V253.57C69.5801 256.01 71.5701 257.99 74.0101 257.99H112.47C114.92 257.99 116.9 256.01 116.9 253.57V215.1C116.9 212.66 114.92 210.68 112.47 210.68ZM108.74 249.83H77.7401V218.84H108.74V249.83Z" fill="#123C4D" />
                                <path opacity="0.36" d="M263.62 210.68H225.16C222.72 210.68 220.73 212.66 220.73 215.1V253.57C220.73 256.01 222.72 257.99 225.16 257.99H263.62C266.07 257.99 268.05 256.01 268.05 253.57V215.1C268.05 212.66 266.07 210.68 263.62 210.68ZM259.89 249.83H228.89V218.84H259.89V249.83Z" fill="#123C4D" />
                                <path opacity="0.36" d="M252.381 308.35H84.9809C79.5709 308.35 74.6709 310.54 71.1209 314.09C67.5809 317.63 65.3809 322.53 65.3809 327.95C65.3809 338.77 74.1609 347.54 84.9809 347.54H252.381C257.791 347.54 262.691 345.35 266.241 341.8C269.781 338.26 271.981 333.36 271.981 327.95C271.981 317.12 263.201 308.35 252.381 308.35ZM259.281 335.93C257.241 337.97 254.421 339.24 251.301 339.24H86.0609C79.8309 339.24 74.7709 334.18 74.7709 327.95C74.7709 324.83 76.0409 322.01 78.0809 319.96C80.1209 317.91 82.9409 316.65 86.0609 316.65H251.301C257.541 316.65 262.591 321.71 262.591 327.95C262.591 331.07 261.331 333.89 259.281 335.93Z" fill="#123C4D" />
                                <path opacity="0.36" d="M252.381 388.54H84.9809C79.5709 388.54 74.6709 390.73 71.1209 394.28C67.5809 397.82 65.3809 402.72 65.3809 408.14C65.3809 418.96 74.1609 427.73 84.9809 427.73H252.381C257.791 427.73 262.691 425.54 266.241 421.99C269.781 418.45 271.981 413.55 271.981 408.14C271.981 397.31 263.201 388.54 252.381 388.54ZM259.281 416.12C257.241 418.16 254.421 419.43 251.301 419.43H86.0609C79.8309 419.43 74.7709 414.37 74.7709 408.14C74.7709 405.02 76.0409 402.2 78.0809 400.15C80.1209 398.1 82.9409 396.84 86.0609 396.84H251.301C257.541 396.84 262.591 401.9 262.591 408.14C262.591 411.26 261.331 414.08 259.281 416.12Z" fill="#123C4D" />
                                <path opacity="0.36" d="M252.381 470.23H84.9809C79.5709 470.23 74.6709 472.42 71.1209 475.97C67.5809 479.51 65.3809 484.41 65.3809 489.83C65.3809 500.65 74.1609 509.42 84.9809 509.42H252.381C257.791 509.42 262.691 507.23 266.241 503.68C269.781 500.14 271.981 495.24 271.981 489.83C271.981 479 263.201 470.23 252.381 470.23ZM259.281 497.81C257.241 499.85 254.421 501.12 251.301 501.12H86.0609C79.8309 501.12 74.7709 496.06 74.7709 489.83C74.7709 486.71 76.0409 483.89 78.0809 481.84C80.1209 479.79 82.9409 478.53 86.0609 478.53H251.301C257.541 478.53 262.591 483.59 262.591 489.83C262.591 492.95 261.331 495.77 259.281 497.81Z" fill="#123C4D" />
                                <path d="M1068.64 0H809.29C787.59 0 770 17.59 770 39.29V548.49C770 570.19 787.59 587.78 809.29 587.78H1068.64C1090.35 587.78 1107.94 570.19 1107.94 548.49V39.29C1107.94 17.59 1090.35 0 1068.64 0ZM1098 547.18C1098 564.75 1083.76 578.99 1066.19 578.99H811.75C794.18 578.99 779.94 564.75 779.94 547.18V40.61C779.94 23.04 794.18 8.79 811.75 8.79H1066.19C1083.76 8.79 1098 23.04 1098 40.61V547.18Z" fill="url(#gradient-2)">
                                    <animate attributeName="fill" values="#123C4D;#123C4D" dur="1s" repeatCount="indefinite" begin="3.5s" />
                                </path>
                                <path opacity="0.36" d="M800.551 31.5303V557.95H1077.39V31.5303H800.551ZM1067.7 549.17H810.241V40.3003H1067.7V549.17Z" fill="#123C4D" />
                                <path opacity="0.36" d="M882.47 70.2598H844.01C841.57 70.2598 839.58 72.2398 839.58 74.6798V113.15C839.58 115.59 841.57 117.57 844.01 117.57H882.47C884.92 117.57 886.9 115.59 886.9 113.15V74.6798C886.9 72.2398 884.92 70.2598 882.47 70.2598ZM878.74 109.41H847.74V78.4198H878.74V109.41Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1033.62 70.2598H995.16C992.72 70.2598 990.73 72.2398 990.73 74.6798V113.15C990.73 115.59 992.72 117.57 995.16 117.57H1033.62C1036.07 117.57 1038.05 115.59 1038.05 113.15V74.6798C1038.05 72.2398 1036.07 70.2598 1033.62 70.2598ZM1029.89 109.41H998.89V78.4198H1029.89V109.41Z" fill="#123C4D" />
                                <path opacity="0.36" d="M882.47 140.47H844.01C841.57 140.47 839.58 142.45 839.58 144.89V183.36C839.58 185.8 841.57 187.78 844.01 187.78H882.47C884.92 187.78 886.9 185.8 886.9 183.36V144.89C886.9 142.45 884.92 140.47 882.47 140.47ZM878.74 179.62H847.74V148.63H878.74V179.62Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1033.62 140.47H995.16C992.72 140.47 990.73 142.45 990.73 144.89V183.36C990.73 185.8 992.72 187.78 995.16 187.78H1033.62C1036.07 187.78 1038.05 185.8 1038.05 183.36V144.89C1038.05 142.45 1036.07 140.47 1033.62 140.47ZM1029.89 179.62H998.89V148.63H1029.89V179.62Z" fill="#123C4D" />
                                <path opacity="0.36" d="M882.47 210.68H844.01C841.57 210.68 839.58 212.66 839.58 215.1V253.57C839.58 256.01 841.57 257.99 844.01 257.99H882.47C884.92 257.99 886.9 256.01 886.9 253.57V215.1C886.9 212.66 884.92 210.68 882.47 210.68ZM878.74 249.83H847.74V218.84H878.74V249.83Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1033.62 210.68H995.16C992.72 210.68 990.73 212.66 990.73 215.1V253.57C990.73 256.01 992.72 257.99 995.16 257.99H1033.62C1036.07 257.99 1038.05 256.01 1038.05 253.57V215.1C1038.05 212.66 1036.07 210.68 1033.62 210.68ZM1029.89 249.83H998.89V218.84H1029.89V249.83Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1022.38 308.35H854.981C849.571 308.35 844.671 310.54 841.121 314.09C837.581 317.63 835.381 322.53 835.381 327.95C835.381 338.77 844.161 347.54 854.981 347.54H1022.38C1027.79 347.54 1032.69 345.35 1036.24 341.8C1039.78 338.26 1041.98 333.36 1041.98 327.95C1041.98 317.12 1033.2 308.35 1022.38 308.35ZM1029.28 335.93C1027.24 337.97 1024.42 339.24 1021.3 339.24H856.061C849.831 339.24 844.771 334.18 844.771 327.95C844.771 324.83 846.041 322.01 848.081 319.96C850.121 317.91 852.941 316.65 856.061 316.65H1021.3C1027.54 316.65 1032.59 321.71 1032.59 327.95C1032.59 331.07 1031.33 333.89 1029.28 335.93Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1022.38 388.54H854.981C849.571 388.54 844.671 390.73 841.121 394.28C837.581 397.82 835.381 402.72 835.381 408.14C835.381 418.96 844.161 427.73 854.981 427.73H1022.38C1027.79 427.73 1032.69 425.54 1036.24 421.99C1039.78 418.45 1041.98 413.55 1041.98 408.14C1041.98 397.31 1033.2 388.54 1022.38 388.54ZM1029.28 416.12C1027.24 418.16 1024.42 419.43 1021.3 419.43H856.061C849.831 419.43 844.771 414.37 844.771 408.14C844.771 405.02 846.041 402.2 848.081 400.15C850.121 398.1 852.941 396.84 856.061 396.84H1021.3C1027.54 396.84 1032.59 401.9 1032.59 408.14C1032.59 411.26 1031.33 414.08 1029.28 416.12Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1022.38 470.23H854.981C849.571 470.23 844.671 472.42 841.121 475.97C837.581 479.51 835.381 484.41 835.381 489.83C835.381 500.65 844.161 509.42 854.981 509.42H1022.38C1027.79 509.42 1032.69 507.23 1036.24 503.68C1039.78 500.14 1041.98 495.24 1041.98 489.83C1041.98 479 1033.2 470.23 1022.38 470.23ZM1029.28 497.81C1027.24 499.85 1024.42 501.12 1021.3 501.12H856.061C849.831 501.12 844.771 496.06 844.771 489.83C844.771 486.71 846.041 483.89 848.081 481.84C850.121 479.79 852.941 478.53 856.061 478.53H1021.3C1027.54 478.53 1032.59 483.59 1032.59 489.83C1032.59 492.95 1031.33 495.77 1029.28 497.81Z" fill="#123C4D" />
                                <path d="M683.64 0H424.29C402.59 0 385 17.59 385 39.29V548.49C385 570.19 402.59 587.78 424.29 587.78H683.64C705.35 587.78 722.94 570.19 722.94 548.49V39.29C722.94 17.59 705.35 0 683.64 0ZM713 547.18C713 564.75 698.76 578.99 681.19 578.99H426.75C409.18 578.99 394.94 564.75 394.94 547.18V40.61C394.94 23.04 409.18 8.79 426.75 8.79H681.19C698.76 8.79 713 23.04 713 40.61V547.18Z" fill="url(#gradient-3)">
                                    <animate attributeName="fill" values="#123C4D;#123C4D" dur="1s" repeatCount="indefinite" begin="2.5s" />
                                </path>
                                <path opacity="0.36" d="M415.551 31.5303V557.95H692.391V31.5303H415.551ZM682.701 549.17H425.241V40.3003H682.701V549.17Z" fill="#123C4D" />
                                <path opacity="0.36" d="M497.47 70.2598H459.01C456.57 70.2598 454.58 72.2398 454.58 74.6798V113.15C454.58 115.59 456.57 117.57 459.01 117.57H497.47C499.92 117.57 501.9 115.59 501.9 113.15V74.6798C501.9 72.2398 499.92 70.2598 497.47 70.2598ZM493.74 109.41H462.74V78.4198H493.74V109.41Z" fill="#123C4D" />
                                <path opacity="0.36" d="M648.62 70.2598H610.16C607.72 70.2598 605.73 72.2398 605.73 74.6798V113.15C605.73 115.59 607.72 117.57 610.16 117.57H648.62C651.07 117.57 653.05 115.59 653.05 113.15V74.6798C653.05 72.2398 651.07 70.2598 648.62 70.2598ZM644.89 109.41H613.89V78.4198H644.89V109.41Z" fill="#123C4D" />
                                <path opacity="0.36" d="M497.47 140.47H459.01C456.57 140.47 454.58 142.45 454.58 144.89V183.36C454.58 185.8 456.57 187.78 459.01 187.78H497.47C499.92 187.78 501.9 185.8 501.9 183.36V144.89C501.9 142.45 499.92 140.47 497.47 140.47ZM493.74 179.62H462.74V148.63H493.74V179.62Z" fill="#123C4D" />
                                <path opacity="0.36" d="M648.62 140.47H610.16C607.72 140.47 605.73 142.45 605.73 144.89V183.36C605.73 185.8 607.72 187.78 610.16 187.78H648.62C651.07 187.78 653.05 185.8 653.05 183.36V144.89C653.05 142.45 651.07 140.47 648.62 140.47ZM644.89 179.62H613.89V148.63H644.89V179.62Z" fill="#123C4D" />
                                <path opacity="0.36" d="M497.47 210.68H459.01C456.57 210.68 454.58 212.66 454.58 215.1V253.57C454.58 256.01 456.57 257.99 459.01 257.99H497.47C499.92 257.99 501.9 256.01 501.9 253.57V215.1C501.9 212.66 499.92 210.68 497.47 210.68ZM493.74 249.83H462.74V218.84H493.74V249.83Z" fill="#123C4D" />
                                <path opacity="0.36" d="M648.62 210.68H610.16C607.72 210.68 605.73 212.66 605.73 215.1V253.57C605.73 256.01 607.72 257.99 610.16 257.99H648.62C651.07 257.99 653.05 256.01 653.05 253.57V215.1C653.05 212.66 651.07 210.68 648.62 210.68ZM644.89 249.83H613.89V218.84H644.89V249.83Z" fill="#123C4D" />
                                <path opacity="0.36" d="M637.381 308.35H469.981C464.571 308.35 459.671 310.54 456.121 314.09C452.581 317.63 450.381 322.53 450.381 327.95C450.381 338.77 459.161 347.54 469.981 347.54H637.381C642.791 347.54 647.691 345.35 651.241 341.8C654.781 338.26 656.981 333.36 656.981 327.95C656.981 317.12 648.201 308.35 637.381 308.35ZM644.281 335.93C642.241 337.97 639.421 339.24 636.301 339.24H471.061C464.831 339.24 459.771 334.18 459.771 327.95C459.771 324.83 461.041 322.01 463.081 319.96C465.121 317.91 467.941 316.65 471.061 316.65H636.301C642.541 316.65 647.591 321.71 647.591 327.95C647.591 331.07 646.331 333.89 644.281 335.93Z" fill="#123C4D" />
                                <path opacity="0.36" d="M637.381 388.54H469.981C464.571 388.54 459.671 390.73 456.121 394.28C452.581 397.82 450.381 402.72 450.381 408.14C450.381 418.96 459.161 427.73 469.981 427.73H637.381C642.791 427.73 647.691 425.54 651.241 421.99C654.781 418.45 656.981 413.55 656.981 408.14C656.981 397.31 648.201 388.54 637.381 388.54ZM644.281 416.12C642.241 418.16 639.421 419.43 636.301 419.43H471.061C464.831 419.43 459.771 414.37 459.771 408.14C459.771 405.02 461.041 402.2 463.081 400.15C465.121 398.1 467.941 396.84 471.061 396.84H636.301C642.541 396.84 647.591 401.9 647.591 408.14C647.591 411.26 646.331 414.08 644.281 416.12Z" fill="#123C4D" />
                                <path opacity="0.36" d="M637.381 470.23H469.981C464.571 470.23 459.671 472.42 456.121 475.97C452.581 479.51 450.381 484.41 450.381 489.83C450.381 500.65 459.161 509.42 469.981 509.42H637.381C642.791 509.42 647.691 507.23 651.241 503.68C654.781 500.14 656.981 495.24 656.981 489.83C656.981 479 648.201 470.23 637.381 470.23ZM644.281 497.81C642.241 499.85 639.421 501.12 636.301 501.12H471.061C464.831 501.12 459.771 496.06 459.771 489.83C459.771 486.71 461.041 483.89 463.081 481.84C465.121 479.79 467.941 478.53 471.061 478.53H636.301C642.541 478.53 647.591 483.59 647.591 489.83C647.591 492.95 646.331 495.77 644.281 497.81Z" fill="#123C4D" />
                                <path d="M1453.64 0H1194.29C1172.59 0 1155 17.59 1155 39.29V548.49C1155 570.19 1172.59 587.78 1194.29 587.78H1453.64C1475.35 587.78 1492.94 570.19 1492.94 548.49V39.29C1492.94 17.59 1475.35 0 1453.64 0ZM1483 547.18C1483 564.75 1468.76 578.99 1451.19 578.99H1196.75C1179.18 578.99 1164.94 564.75 1164.94 547.18V40.61C1164.94 23.04 1179.18 8.79 1196.75 8.79H1451.19C1468.76 8.79 1483 23.04 1483 40.61V547.18Z" fill="url(#gradient-4)">
                                    <animate attributeName="fill" values="#123C4D;#123C4D" dur="1s" repeatCount="indefinite" begin="4.5s" />
                                </path>
                                <path opacity="0.36" d="M1185.55 31.5303V557.95H1462.39V31.5303H1185.55ZM1452.7 549.17H1195.24V40.3003H1452.7V549.17Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1267.47 70.2598H1229.01C1226.57 70.2598 1224.58 72.2398 1224.58 74.6798V113.15C1224.58 115.59 1226.57 117.57 1229.01 117.57H1267.47C1269.92 117.57 1271.9 115.59 1271.9 113.15V74.6798C1271.9 72.2398 1269.92 70.2598 1267.47 70.2598ZM1263.74 109.41H1232.74V78.4198H1263.74V109.41Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1418.62 70.2598H1380.16C1377.72 70.2598 1375.73 72.2398 1375.73 74.6798V113.15C1375.73 115.59 1377.72 117.57 1380.16 117.57H1418.62C1421.07 117.57 1423.05 115.59 1423.05 113.15V74.6798C1423.05 72.2398 1421.07 70.2598 1418.62 70.2598ZM1414.89 109.41H1383.89V78.4198H1414.89V109.41Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1267.47 140.47H1229.01C1226.57 140.47 1224.58 142.45 1224.58 144.89V183.36C1224.58 185.8 1226.57 187.78 1229.01 187.78H1267.47C1269.92 187.78 1271.9 185.8 1271.9 183.36V144.89C1271.9 142.45 1269.92 140.47 1267.47 140.47ZM1263.74 179.62H1232.74V148.63H1263.74V179.62Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1418.62 140.47H1380.16C1377.72 140.47 1375.73 142.45 1375.73 144.89V183.36C1375.73 185.8 1377.72 187.78 1380.16 187.78H1418.62C1421.07 187.78 1423.05 185.8 1423.05 183.36V144.89C1423.05 142.45 1421.07 140.47 1418.62 140.47ZM1414.89 179.62H1383.89V148.63H1414.89V179.62Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1267.47 210.68H1229.01C1226.57 210.68 1224.58 212.66 1224.58 215.1V253.57C1224.58 256.01 1226.57 257.99 1229.01 257.99H1267.47C1269.92 257.99 1271.9 256.01 1271.9 253.57V215.1C1271.9 212.66 1269.92 210.68 1267.47 210.68ZM1263.74 249.83H1232.74V218.84H1263.74V249.83Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1418.62 210.68H1380.16C1377.72 210.68 1375.73 212.66 1375.73 215.1V253.57C1375.73 256.01 1377.72 257.99 1380.16 257.99H1418.62C1421.07 257.99 1423.05 256.01 1423.05 253.57V215.1C1423.05 212.66 1421.07 210.68 1418.62 210.68ZM1414.89 249.83H1383.89V218.84H1414.89V249.83Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1407.38 308.35H1239.98C1234.57 308.35 1229.67 310.54 1226.12 314.09C1222.58 317.63 1220.38 322.53 1220.38 327.95C1220.38 338.77 1229.16 347.54 1239.98 347.54H1407.38C1412.79 347.54 1417.69 345.35 1421.24 341.8C1424.78 338.26 1426.98 333.36 1426.98 327.95C1426.98 317.12 1418.2 308.35 1407.38 308.35ZM1414.28 335.93C1412.24 337.97 1409.42 339.24 1406.3 339.24H1241.06C1234.83 339.24 1229.77 334.18 1229.77 327.95C1229.77 324.83 1231.04 322.01 1233.08 319.96C1235.12 317.91 1237.94 316.65 1241.06 316.65H1406.3C1412.54 316.65 1417.59 321.71 1417.59 327.95C1417.59 331.07 1416.33 333.89 1414.28 335.93Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1407.38 388.54H1239.98C1234.57 388.54 1229.67 390.73 1226.12 394.28C1222.58 397.82 1220.38 402.72 1220.38 408.14C1220.38 418.96 1229.16 427.73 1239.98 427.73H1407.38C1412.79 427.73 1417.69 425.54 1421.24 421.99C1424.78 418.45 1426.98 413.55 1426.98 408.14C1426.98 397.31 1418.2 388.54 1407.38 388.54ZM1414.28 416.12C1412.24 418.16 1409.42 419.43 1406.3 419.43H1241.06C1234.83 419.43 1229.77 414.37 1229.77 408.14C1229.77 405.02 1231.04 402.2 1233.08 400.15C1235.12 398.1 1237.94 396.84 1241.06 396.84H1406.3C1412.54 396.84 1417.59 401.9 1417.59 408.14C1417.59 411.26 1416.33 414.08 1414.28 416.12Z" fill="#123C4D" />
                                <path opacity="0.36" d="M1407.38 470.23H1239.98C1234.57 470.23 1229.67 472.42 1226.12 475.97C1222.58 479.51 1220.38 484.41 1220.38 489.83C1220.38 500.65 1229.16 509.42 1239.98 509.42H1407.38C1412.79 509.42 1417.69 507.23 1421.24 503.68C1424.78 500.14 1426.98 495.24 1426.98 489.83C1426.98 479 1418.2 470.23 1407.38 470.23ZM1414.28 497.81C1412.24 499.85 1409.42 501.12 1406.3 501.12H1241.06C1234.83 501.12 1229.77 496.06 1229.77 489.83C1229.77 486.71 1231.04 483.89 1233.08 481.84C1235.12 479.79 1237.94 478.53 1241.06 478.53H1406.3C1412.54 478.53 1417.59 483.59 1417.59 489.83C1417.59 492.95 1416.33 495.77 1414.28 497.81Z" fill="#123C4D" />
                            </svg>
                            <AnimatedText><h1 className="nexa-light text-white text-center">Automated <div className="nexa-bold text-gradient">Documentation</div></h1></AnimatedText>
                        </div>
                        <h5 className="text-white dmsans-regular"><AnimatedText>Our solutions allow unprecedented visibility and agility within the data center. Automating real-time, accurate documentation makes it possible to better manage data center operations on a daily basis and respond to security concerns, compliance demands, and unplanned downtime more quickly. </AnimatedText></h5>
                        <AnimatedText>
                            <Link target="_blank" to={AutomatedDocumentation.solutionBrief} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color">
                                <i className="fa-solid fa-arrow-down me-2"></i>SOLUTION BRIEF
                            </Link>
                        </AnimatedText>
                    </div>
                </div>
                {/* Datacenter Colocation Hero Section Ends */}
                <div className="bg-blue">
                    <div className="container solution-feature-container">
                        <div className="solution-features">
                            <div className="row flex-column-reverse flex-sm-column flex-lg-row">
                                <div className="col-lg-6  order-lg-1 order-sm-2">
                                    <div className="row">
                                        {AutomatedDocumentation.features.map((value, index) => {
                                            return (
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-5">
                                                    <div className="solution-features-elements bg-blue">
                                                        <img className="icon" src={value.icon}></img>
                                                        <AnimatedText><h4 className="text-white features-heading nexa-bold" dangerouslySetInnerHTML={{ __html: value.title }}></h4></AnimatedText>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-lg-6 order-lg-2 order-sm-1 visibility-wrapper">
                                    <h2 className="nexa-bold text-white"><AnimatedText>Physical Layer <span className="nexa-bold text-primary-green">Visibility</span></AnimatedText></h2>
                                    <h5 className="text-white dmsans-regular"><AnimatedText>The physical layer of patch panels, cables and connection points has so far been invisible to software, except through the medium of human technicians manually documenting the connections that make up the physical layer of data center infrastructure. The solution of automated documentation removes this persistent hindrance and makes the physical layer directly visible and controllable by software, eliminating the need for a human mediator to enter data into an Excel spreadsheet.</AnimatedText></h5>
                                </div>
                            </div>
                        </div>
                        {/* Network Visibility Section Starts */}
                        <div className="layer-intelligence-wrapper">
                            <div className="row">
                                <div className="col-lg-6 col-sm-7 my-auto">
                                    <div className="network-bg">
                                        <h2 className="text-primary-green nexa-bold intelligent-automation"><AnimatedText>Intelligence <span className="text-white">Through Automation</span></AnimatedText></h2>
                                        <h5 className="text-white dmsans-regular secure-and-intelligent-layer-info"><AnimatedText>Green Lambda solution adds intelligence to the physical layer. One of the benefits of this technology is that after AllPath® Director reads information from the intelligent cables, it understands what is plugged where and creates automated documentation of the topology including moves, adds, and changes (MACs). This allows for accurate physical layer documentation 100% of the time. This will bring your data center to a state of blissful zen.</AnimatedText></h5>
                                        <AnimatedText>
                                        <Link target="_blank" to={AutomatedDocumentation.solutionBrief} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-5">
                                            <i className="fa-solid fa-arrow-down me-2"></i>SOLUTION BRIEF
                                        </Link>
                                        </AnimatedText>
                                    </div>
                                </div>
                                <div className="offset-lg-1 col-sm-5 col-lg-5">
                                    <img className="physical-layer-intelligence-image" src={AutomatedDocumentation.intelligentPhysicalLayer} />
                                </div>

                            </div>
                        </div>
                        {/* Network Visibility Section Ends */}
                        <SolutionVideo videoSection={AutomatedDocumentation.videoSection} />

                        <SolutionAccordion features={AutomatedDocumentation.accordionFeatures} featureTitle={AutomatedDocumentation.featureTitle} />
                    </div>

                </div>

                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div>
        </>
    );
}