import SolutionAccordion from "../solution-feature-accordion/solution-accordion";
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./physical-layer-security.scss";
import SolutionVideo from "../solution-video/solution-video";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AnimatedText from "../animated-text/animated-text";

export default function PhysicalLayerSecurity() {
    const PhysicalLayerSecurity = {
        featureTitle: 'Physical Layer Security',
        zeroTrust: '/solutions/physical-layer-security-assets/zero-trust.svg',
        secureIntelligentLayer: '/solutions/physical-layer-security-assets/secure-intelligent-layer.png',
        solutionBrief: '/glc-resources/solutionbriefs/Physical Layer Security.pdf',
        whiteDownloadIcon: '/products/white-download-icon.svg',
        videoSection: {
            divider: '/solutions/datacenter-colocation-assets/divider.svg',
            icon: '/solutions/physical-layer-security-assets/secure-physical-layer.png',
            divider1: '/solutions/datacenter-colocation-assets/divider1.svg',
            solutionDiagram: '/solutions/physical-layer-security-assets/how-it-works-diagram.png',
            diagramCaption: 'Real time network information and reporting. No move, add or change goes unnoticed!',
            titleSolid1: 'Keeping Your',
            titleStroke: 'Physical Layer',
            titleSolid: 'Secure'
        },
        accordionFeatures: [
            {
                image: '/solutions/physical-layer-security-assets/audit-logs.png',
                titleGreenPart: 'Audit',
                titleWhitePart: 'Logs',
                information: 'The Audit Log feature keeps track of the present state of the network along with the timing and location of all past modifications, hence keeping the data center network secure. This enables prompt response to any security threat.'
            },
            {
                image: '/solutions/physical-layer-security-assets/alarms.png',
                titleGreenPart: 'Alarms',
                titleWhitePart: '',
                information: 'Alarms generate alerts to inform network operators of unauthorized changes including fiber disconnections, power supply failures, and unauthorized repatching. What would have been a lengthy outage or security breach is now a rapid remedy.'
            },
            {
                image: '/solutions/physical-layer-security-assets/system-reports.png',
                titleGreenPart: 'System',
                titleWhitePart: 'Reports',
                information: 'System reports feature generates cable activity/inventory reports, panel activity/inventory and other customized reports, thus keeping network administrators fully aware of the network condition.'
            },
            {
                image: '/solutions/physical-layer-security-assets/network-tap.png',
                titleGreenPart: 'Network',
                titleWhitePart: 'TAP',
                information: 'Tapping a network device involves capturing the transmit/receive traffic and redirecting it to a monitoring device.'
            },

        ]
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Physical Layer Security | Green Lambda Corporation</title>
                    <meta name="description" content="Providing physical layer security with real-time documentation, transparency, and intelligence solutions so nothing in your network goes unknown." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="physical-layer-security-wrapper bg-blue">
                {/* Nav Start */}
                <Nav />
                {/* Nav End */}
                {/* Capacity Management Hero Section Start */}
                <div className="hero position-relative">

                    <div className="hero-content hero-bg d-flex justify-content-center align-items-center flex-column position-relative">
                        <div className="hero-heading-bg"><AnimatedText> <h1 className="nexa-light text-white text-center">Physical Layer <div className="nexa-bold text-gradient">Security</div></h1></AnimatedText> </div>
                        <h5 className="text-white dmsans-regular justify-content-center"><AnimatedText>Green Lambda drives physical layer security from real-time documentation, visibility into every physical layer change through the comprehensive event logs and the ability to trigger actions based on changes in the network. </AnimatedText></h5>
                        <AnimatedText>
                            <Link to={PhysicalLayerSecurity.solutionBrief} target="_blank" className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color"><i className="fa-solid fa-arrow-down me-2"></i>SOLUTION BRIEF</Link>
                        </AnimatedText>
                    </div>
                </div>
                {/* Capacity Management Hero Section Ends */}
                <div className="container bg-blue">
                    <div className="solution-features">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className="nexa-bold text-white text-center mb-0"><AnimatedText>Zero <span className="nexa-bold text-primary-green">Trust</span></AnimatedText></h2>
                            <h5 className="text-white dmsans-regular"><AnimatedText>As physical layer security experts we have a <span className="text-primary-green">zero-trust policy</span> which is implemented by making the <span className="text-primary-green">physical layer transparent.</span> Transparency makes it easy for the network administrators to identify, <span className="text-primary-green">monitor and track</span> the physical layer.</AnimatedText></h5>
                            <div className="container">
                                <div className="row">
                                    <div className="offset-lg-1 col-lg-10">
                                        <img className="w-100" src={PhysicalLayerSecurity.zeroTrust} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="secure-intelligent-layer-wrapper">
                        <div className="row">
                            <div className="col-lg-5 col-sm-6">
                                <img className="w-100 physical-layer-security-image" src={PhysicalLayerSecurity.secureIntelligentLayer} />
                            </div>
                            <div className="offset-lg-1 col-lg-6 col-sm-6  my-auto">
                                <div className="network-bg">
                                    <h2 className="text-white nexa-bold"><AnimatedText>Secure & Intelligent <span className="text-primary-green">Physical Layer</span></AnimatedText></h2>
                                    <h5 className="text-white dmsans-regular secure-and-intelligent-layer-info"><AnimatedText>The <span className="text-primary-green">physical layer</span> serves as the foundation for any or all network activities. In effect, it is the part of any data center that is most essential. Our solution redesigns the physical layer with integrated security and intelligence to <span className="text-primary-green">maximize data center agility.</span></AnimatedText></h5>
                                </div>
                            </div>
                        </div>
                    </div>

                    <SolutionVideo videoSection={PhysicalLayerSecurity.videoSection} />
                    <div className="security-features">
                        <SolutionAccordion features={PhysicalLayerSecurity.accordionFeatures} featureTitle={PhysicalLayerSecurity.featureTitle} />
                    </div>
                </div>
                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div>
        </>
    );
}