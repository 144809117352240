import SolutionAccordion from "../solution-feature-accordion/solution-accordion";
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./universal-breakout.scss";
import SolutionVideo from "../solution-video/solution-video";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AnimatedText from "../animated-text/animated-text";

export default function UniversalBreakout() {
    const UniversalBreakout = {
        featureTitle: 'Universal Breakout',
        solutionBrief: '/glc-resources/solutionbriefs/Universal Breakout.pdf',
        whiteDownloadIcon: '/products/white-download-icon.svg',
        videoSection: {
            divider: '/solutions/datacenter-colocation-assets/divider.svg',
            icon: '/solutions/universal-breakout-assets/universal-port.png',
            divider1: '/solutions/datacenter-colocation-assets/divider1.svg',
            solutionDiagram: '/solutions/universal-breakout-assets/how-it-works-diagram.svg',
            diagramCaption: 'Breakout switches reduce high density switches to low density switches for easier interoperability.',
            titleStroke: 'Universal Port',
            titleSolid: 'Replicator Removes Breakout Dilemmas!'
        },
        features: [
            {
                icon: '/solutions/universal-breakout-assets/feature-1.png',
                title: 'Tri-color LEDs',
                class: "feature-margin-1"
            },
            {
                icon: '/solutions/universal-breakout-assets/feature-2.svg',
                title: 'Breakout Visibility',
                class: "feature-margin-2"
            },
            {
                icon: '/solutions/universal-breakout-assets/feature-3.png',
                title: 'High-density Switch Breakout',
                class: "feature-margin-3"
            },
            {
                icon: '/solutions/universal-breakout-assets/feature-4.png',
                title: 'Easy Server-switch Connection',
                class: "feature-margin-4"
            },
        ],
        accordionFeatures: [
            {
                image: '/solutions/universal-breakout-assets/breakout-switches.png',
                titleGreenPart: 'Breakout ',
                titleWhitePart: 'Switches',
                information: 'This feature addresses the breakout issue by breaking out high density switch ports into 4 LC duplex ports. The 40G and 100G ports are split into four 10G ports and four 25G ports.'
            },
            {
                image: '/solutions/universal-breakout-assets/breakout-visibility.png',
                titleGreenPart: 'Breakout ',
                titleWhitePart: 'Visibility',
                information: 'Each LC duplex port and MPO has a tri-color LED that can be used to guide the on-site technician to service the ports. These LEDs are managed via the originating switch, REST API, or Web Interface on the UPR.'
            },
            {
                image: '/solutions/universal-breakout-assets/server-switch-connection.png',
                titleGreenPart: 'Server-switch ',
                titleWhitePart: 'Connection',
                information: 'Breaking out the high density switches into low density makes connections between end-devices (i.e. servers) and these switches trouble-free.'
            },

        ]
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Universal Breakout | Green Lambda Corporation</title>
                    <meta name="description" content="Simplified high-density switch breakouts with Green Lambda's universal port replicator; enabling easy server-switch connections." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="universal-breakout-wrapper bg-blue">
                {/* Capacity Management Hero Section Start */}
                <div className="hero position-relative">
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="hero-content bg-blue d-flex justify-content-center align-items-center flex-column position-relative">
                        <div className="hero-bg">
                            <div className="hero-heading-bg"><AnimatedText> <h1 className="nexa-light text-white text-center">Universal <div className="nexa-bold text-gradient">Breakout</div></h1></AnimatedText> </div>
                            <h5 className="text-white dmsans-regular justify-content-center"><AnimatedText>Universal Port Replicator can breakout any QSFP into 4 LC duplex ports, each having tri-color LEDs, managed via originating switch, REST API, or web interface, hence resolving breakout dilemma for high density switch ports.</AnimatedText></h5>
                            <AnimatedText>
                                <Link to={UniversalBreakout.solutionBrief} target="_blank" className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color"><i className="fa-solid fa-arrow-down me-2"></i>SOLUTION BRIEF</Link>
                            </AnimatedText>
                        </div>
                    </div>
                </div>
                {/* Capacity Management Hero Section Ends */}
                <div className="container bg-blue solution-features-container">
                    <div className="solution-features">
                        <div className="row">
                            <div className="col-lg-6 visibility-wrapper">
                                <h2 className="nexa-bold text-white"><AnimatedText>High Density <span className="nexa-bold text-primary-green">Switch Dilemma</span></AnimatedText></h2>
                                <h5 className="text-white dmsans-regular">
                                    <AnimatedText>
                                        <div>Most enterprise networks and hyper scale data centers prefer to use high density switches such as <span className="text-primary-green">40G, 100G</span> are common and <span className="text-primary-green">400G</span> is in the near future. However, one of the dilemmas of high density switches is not all devices support high density ports. </div>
                                    </AnimatedText>
                                    <AnimatedText>
                                        <div className="mt-5">There is a need to breakout 40G ports into 4x10G ports and 100G ports into 4x25G ports. Our universal port replicator addresses this issue by breaking out any QSFP port into <span className="text-primary-green">4 LC duplex ports</span>. This makes end devices to easily connect to these kinds of switches.</div>
                                    </AnimatedText>
                                </h5>
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    {UniversalBreakout.features.map((value, index) => {
                                        return (
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-5">
                                                <div className={'solution-features-elements bg-blue ' + value.class} >
                                                    <img className="icon" src={value.icon}></img>
                                                    <AnimatedText> <h4 className="text-white features-heading nexa-bold">{value.title}</h4></AnimatedText>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Network Visibility Section Starts */}
                    <div className="network-visibility-wrapper">
                        <div className="network-bg">
                            <h2 className="text-white nexa-bold"><AnimatedText>Port Service <span className="text-primary-green">Simplified</span></AnimatedText></h2>
                            <h5 className="text-white"><AnimatedText>Consider yourself a data center technician who needs to service a port that is part of a <span className="text-primary-green">40G breakout.</span> You must first locate the switch, then the <span className="text-primary-greem">40G switch port,</span> and then the individual <span className="text-primary-green">10G port.</span> Because the port has been separated, there are no <span className="text-primary-green">physical indicators</span> for each individual port on the original switch. As a result, the technician's work becomes difficult. This is where <span className="text-primary-green">tri-LEDs</span> come into play. To service a port, a technician can now just follow the <span className="text-primary-green">"lights"</span> on the port.</AnimatedText></h5>
                        </div>
                    </div>
                    {/* Network Visibility Section Ends */}
                    <SolutionVideo videoSection={UniversalBreakout.videoSection} />
                    <div className="universal-features">
                        <SolutionAccordion features={UniversalBreakout.accordionFeatures} featureTitle={UniversalBreakout.featureTitle} />
                    </div>
                </div>
                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div>
        </>
    );
}