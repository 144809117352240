import Footer from "../footer/footer";
import Nav from "../nav/nav";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./glc-team.scss";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AnimatedText from "../animated-text/animated-text";

export default function GLCTeam() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1
    }
    const GLCTeam = {
        heroImageBg: '/company/glc-team/team-hero-bg.png',
        heroImageMobileBg: '/company/glc-team/team-hero-mobile-bg.png',
        glcTeams: '/company/glc-team/glc-teams.png',
        heroGIF: '/company/glc-team/team-gif.mp4',
        coreLogo: '/company/glc-team/glc-core-logo.svg',
        glcCulture: '/company/glc-team/glc-culture.jpg',
        glcCultureMobile: '/company/glc-team/glc-culture-mobile.png',
        glcHiringSteps: '/company/glc-team/hiring-steps.svg',
        glcHiringStepsMobile: '/company/glc-team/hiring-steps-mobile.png',
        glcArrow: '/company/glc-team/arrow.svg',
        glcPerksContainer1: [
            {
                'icon': '/company/glc-team/health-insurance.png',
                'title': 'Health Insurance'
            },
            {
                'icon': '/company/glc-team/provident-fund.png',
                'title': 'Provident Fund'
            },
            {
                'icon': '/company/glc-team/sponsored-travel.png',
                'title': 'Sponsored Business Travel'
            },
            {
                'icon': '/company/glc-team/employee-old-age-benefits.png',
                'title': 'Employee Old-Age Benefits'
            },
            {
                'icon': '/company/glc-team/global-exposure.png',
                'title': 'Global Exposure with Hyperscale Clients'
            },
            {
                'icon': '/company/glc-team/dollar-adjustment.png',
                'title': 'Dollar Adjustment Allowance'
            },
            {
                'icon': '/company/glc-team/homemade-lunch.png',
                'title': 'Daily Sponsored Homemade Lunch'
            },
            {
                'icon': '/company/glc-team/girls-recharge-room.png',
                'title': 'Dedicated Recharge Room for Girls'
            },
        ],
        glcPerksContainer2: [
            {
                'icon': '/company/glc-team/healthy-work-life-balance.png',
                'title': 'Healthy Work Life Balance'
            },
            {
                'icon': '/company/glc-team/team-lunch.png',
                'title': 'Special Team Lunch on Fri –Yays '
            },
            {
                'icon': '/company/glc-team/quarterly-promotion-review.png',
                'title': 'Quarterly Promotion Review'
            },
            {
                'icon': '/company/glc-team/trust-based-culture.png',
                'title': 'Trust Based Open Culture'
            },
            {
                'icon': '/company/glc-team/bi-weekly-social-hour.png',
                'title': 'Bi-weekly Social Hour'
            },
            {
                'icon': '/company/glc-team/fuel-allowance.png',
                'title': 'Fuel Allowance'
            },
            {
                'icon': '/company/glc-team/exclusive-medical-leaves.png',
                'title': 'Exclusive Medical Leaves for Women'
            },
            {
                'icon': '/company/glc-team/funded-trainings.png',
                'title': 'Company Funded Certifications and Trainings'
            },
        ],
        glcPerksContainer3: [
            {
                'icon': '/company/glc-team/referral-bonuses.png',
                'title': 'Referral Bonuses'
            },
            {
                'icon': '/company/glc-team/team-building-activities.png',
                'title': 'Fun Team Building Activities'
            },
            {
                'icon': '/company/glc-team/401k-plan.png',
                'title': '401(k) plan'
            },
            {
                'icon': '/company/glc-team/girls-getaway.png',
                'title': "Girls Getaway"
            },
            {
                'icon': '/company/glc-team/game-room.png',
                'title': 'Game Room'
            },
            {
                'icon': '/company/glc-team/paid-time-off.png',
                'title': 'Paid Time Off'
            },
            {
                'icon': '/company/glc-team/annual-appraisals-and-increment.png',
                'title': 'Annual Appraisals and Increment'
            },
            {
                'icon': '/company/glc-team/unlimited-snacks.png',
                'title': 'Fully Stocked Kitchen with Unlimited Snacks'
            },
        ],
        glcCorporatePhilosophy: [
            {
                icon: '/company/about-us/transparency.png',
                number: '01',
                label: 'Transparency',
                mobileContentAlignment: 'about-text-left'

            },
            {
                icon: '/company/about-us/positivity.png',
                number: '02',
                label: 'Positivity',
                mobileContentAlignment: 'about-text-right'

            },
            {
                icon: '/company/about-us/togetherness.png',
                number: '03',
                label: 'Togetherness',
                mobileContentAlignment: 'about-text-left'

            }
        ],
        glcJobs: [
            {
                department: 'Product Management',
                jobs: [
                    {
                        title: 'Project Manager ||',
                        location: 'Islamabad, Pakistan',
                    },
                    {
                        title: 'Product Owner',
                        location: 'Islamabad, Pakistan',
                    },
                    {
                        title: 'Associate Project Manager',
                        location: 'Islamabad, Pakistan',
                    },
                    {
                        title: 'PMO',
                        location: 'Islamabad, Pakistan',
                    },
                ],
            },
            {
                department: 'MarCom & Design',
                jobs: [
                    {
                        title: 'Content Writer',
                        location: 'Islamabad, Pakistan',
                    },
                    {
                        title: 'HR Executive',
                        location: 'Islamabad, Pakistan',
                    },
                    {
                        title: 'Technical Writer |',
                        location: 'Islamabad, Pakistan',
                    },
                ],
            },
            {
                department: 'Engineering',
                jobs: [
                    {
                        title: 'Java Developer',
                        location: 'Islamabad, Pakistan',
                    },
                ],
            },
        ],
        // glcTeams: [
        //     {
        //         team: 'Product Management',
        //         image: '/company/glc-team/product-management.svg',
        //         sizeClass: 'slide-item-large',
        //     },
        //     {
        //         team: 'Design',
        //         image: '/company/glc-team/design.svg',
        //         sizeClass: 'slide-item-small'
        //     },
        //     {
        //         team: 'Engineering',
        //         image: '/company/glc-team/engineering.svg',
        //         sizeClass: 'slide-item-large'
        //     },
        //     {
        //         team: 'Web Development',
        //         image: '/company/glc-team/web-development.svg',
        //         sizeClass: 'slide-item-small'
        //     },
        //     {
        //         team: 'Quality Assurance',
        //         image: '/company/glc-team/quality-assurance.svg',
        //         sizeClass: 'slide-item-large'
        //     },
        //     {
        //         team: 'Sales',
        //         image: '/company/glc-team/sales.svg',
        //         sizeClass: 'slide-item-small'
        //     },
        //     {
        //         team: 'Technical Documentation',
        //         image: '/company/glc-team/technical-documentation.svg',
        //         sizeClass: 'slide-item-large'
        //     },
        //     {
        //         team: 'Administration',
        //         image: '/company/glc-team/administration.svg',
        //         sizeClass: 'slide-item-small'
        //     },
        //     {
        //         team: 'Operations',
        //         image: '/company/glc-team/operations.svg',
        //         sizeClass: 'slide-item-large'
        //     },
        //     {
        //         team: 'Marketing',
        //         image: '/company/glc-team/marketing.svg',
        //         sizeClass: 'slide-item-small'
        //     },
        // ],

    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Life at Green Lambda | Green Lambda Corporation</title>
                    <meta name="description" content="A sneak peek into everyday life at Green Lambda. Take a closer look at our values and the benefits that we offer. Also, you can view our hiring process and browse open positions." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="glc-team-wrapper bg-blue">
                <div className="hero position-relative">
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="hero-content bg-blue d-flex align-items-center flex-column position-relative justify-content-center">
                        <div className="container">
                            <div className="hero-top-gradient"></div>
                            <h1 className="nexa-light text-white text-center position-relative z-index-2"><AnimatedText><span className="text-center z-index-2">Life at <span className="nexa-bold text-gradient">Green Lambda</span></span></AnimatedText></h1>
                            <h5 className="text-white dmsans-regular justify-content-center team-info position-relative z-index-2"><AnimatedText>Life at Green Lambda is always bustling with energy and excitement and
                                we wouldn't want it any other way! </AnimatedText></h5>
                                <Link target="_blank" className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color hiring-btn-hero me-4 position-relative z-index-2" to="https://greenlambda.bamboohr.com/careers">
                                    APPLY NOW
                                </Link>
                            <img className="mw-100 d-md-none d-block" src={GLCTeam.heroImageMobileBg} />
                            <img className="mw-100 d-none d-md-block" src={GLCTeam.heroImageBg} />
                            <div className="gif-container d-none d-md-block">
                                <video autoPlay playsInline loop muted className="team-video">
                                    <source src={GLCTeam.heroGIF} type="video/mp4"></source>
                                </video>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="container glc-core-container">
                    {/* Section Core Values Start */}
                    <div className="glc-core-values">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className="text-white nexa-bold"><AnimatedText>What We <span className="text-primary-green">Live By</span></AnimatedText></h2>
                            <h5 className="text-white value-info dmsans-regular"><AnimatedText>At Green Lambda, our core values are more than just words. For us,
                                they're a way of life.</AnimatedText></h5>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="left-mid-gradient d-md-block d-none"></div>
                                <div className="right-mid-gradient"></div>
                                <div className="d-flex justify-content-center">
                                    <div className="value-element text-md-start text-center">
                                        <h3 className='text-white nexa-bold'><AnimatedText>Respect</AnimatedText></h3>
                                        <p className="text-white dmsans-regular mt-4"><AnimatedText>Stay humble, value each other and treat others with dignity and professionalism. Respect is one of the core values at our workplace where everyone is treated with courtesy and kindness.</AnimatedText></p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="value-element element-padding-top text-md-start text-center">
                                        <h3 className='text-white nexa-bold'><AnimatedText>Customer Focus</AnimatedText></h3>
                                        <p className="text-white dmsans-regular mt-4"><AnimatedText>Be awesome, create something magical for the users. We believe in delivering customer-centric solutions by gathering insights into customer needs and delivering solutions that exceed customer expectations.</AnimatedText></p>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-4 text-center d-xxl-block d-md-flex d-none">
                                <img className="mw-100" src={GLCTeam.coreLogo} />
                            </div>
                            <div className="col-md-4">
                                <div className="d-flex justify-content-center">
                                    <div className="value-element text-md-start text-center accountability">
                                        <h3 className='text-white nexa-bold'><AnimatedText>Accountability</AnimatedText></h3>
                                        <p className="text-white dmsans-regular mt-4"><AnimatedText>Take ownership and hold yourself responsible for results. Celebrate success and reflect on any shortcomings. We honor commitments to our customers and to one another.</AnimatedText></p>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="value-element element-padding-top position-relative text-md-start text-center">
                                        <h3 className='text-white nexa-bold'><AnimatedText>Trust</AnimatedText></h3>
                                        <p className="text-white dmsans-regular mt-4"><AnimatedText>Build trust through responsible actions and honest relationships. Trust is the building block of all our values, and we strive to build an atmosphere of trust with our team and customers.</AnimatedText></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Section Core Values End */}

                        {/* Section GLC Perks Start */}
                        <div className="glc-perks">
                            <div className="mid-gradient d-md-block d-none"></div>
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <p className="value-stroke nexa-bold">#GLC Perks</p>
                                <h2 className="text-white nexa-bold value-heading"><AnimatedText>What We <span className="text-primary-green">Offer</span></AnimatedText></h2>
                                <h5 className="text-white perk-info dmsans-regular"><AnimatedText>Discover the <span className="text-primary-green">perks and benefits*</span> that set us apart and make us a great place to work!</AnimatedText></h5>
                            </div>
                            <div className="d-md-flex justify-content-center flex-row perks-flex d-block">
                                <div className="perks-element">
                                    {GLCTeam.glcPerksContainer1.map((value, index) => {
                                        return (
                                            <AnimatedText>
                                                <div className="d-flex align-items-center">
                                                    <img className="mx-4 ms-lg-4 ms-0" src={value.icon} /><p className="text-white dmsans-regular mb-0">{value.title}</p>
                                                </div>
                                            </AnimatedText>
                                        )
                                    })}
                                </div>
                                <div className="perks-element perk-container-gradient">
                                    {GLCTeam.glcPerksContainer2.map((value, index) => {
                                        return (
                                            <AnimatedText>
                                                <div className="d-flex align-items-center">
                                                    <img className="mx-4 ms-lg-4 ms-0" src={value.icon} /><p className="text-white dmsans-regular mb-0">{value.title}</p>
                                                </div>
                                            </AnimatedText>
                                        )
                                    })}
                                </div>
                                <div className="perks-element">
                                    {GLCTeam.glcPerksContainer3.map((value, index) => {
                                        return (
                                            <AnimatedText>
                                                <div className="d-flex align-items-center">
                                                    <img className="mx-4 ms-lg-4 ms-0" src={value.icon} /><p className="text-white dmsans-regular mb-0">{value.title}</p>
                                                </div>
                                            </AnimatedText>
                                        )
                                    })}
                                </div>

                            </div>
                            <div className="mt-5">
                                <p className="text-center text-white dmsans-regular"><AnimatedText> *Differences in laws and regulations may require us to adjust policies based upon geographical locations</AnimatedText></p>
                            </div>
                        </div>
                        {/* Section GLC Perks End */}
                        <div className="glc-corporate bg-blue d-flex justify-content-center align-items-center flex-column">
                            <h3 className="text-leadership-stroke nexa-bold">Corporate Philosophy</h3>
                            <h2 className="text-white nexa-bold brand-margin"><AnimatedText>The <span className="text-primary-green">Green Culture</span></AnimatedText></h2>
                            <h5 className="text-white dmsans-regular text-behind-brand about-info"><AnimatedText>At Green Lambda we promote a culture of transparency, positivity and togetherness.</AnimatedText></h5>
                            <div className="row text-center w-100 mb-5">
                                {GLCTeam.glcCorporatePhilosophy.map((value, index) => {
                                    return (
                                        <div className={'col-lg-4 col-sm-4 ' + value.mobileContentAlignment}>
                                            <img className="stroke-philosophy-icon" src={value.icon} />
                                            <p className="corporate-stroke">{value.number}</p>
                                            <h4 className="text-white nexa-bold margin-label-philosophy">{value.label}</h4>
                                        </div>
                                    )
                                })}
                            </div>
                            <h5 className="text-white dmsans-regular mt-5 corporate-philosophy-text"><AnimatedText>Our team is our greatest asset, and we ensure everyone is respected, recognized and celebrated. This Green Culture is what unites us as one and sparks the <span className="text-primary-green">magic of delivering perfection to our customers.</span> We call it the ‘Green Culture’ because it fosters growth, promotes inclusivity and encourages us to build a legacy that transforms the landscape of the networking industry in times to come.</AnimatedText></h5>
                        </div>


                    </div>

                </div>
                <div className="bg-white">
                    <div className="container glc-culture">
                        <img className="w-100 d-md-block d-none" src={GLCTeam.glcCulture} />
                        <img className="w-100 d-md-none d-block" src={GLCTeam.glcCultureMobile} />
                    </div>

                </div>
                <div className="bg-blue glc-team-container">
                    {/* <img className="glc-arrow" src={GLCTeam.glcArrow}></img> */}
                    <div className="container">
                        <div className="glc-team">
                            <div className="d-flex justify-content-center flex-column align-items-center">
                                <h2 className="nexa-bold text-primary-green text-center"><AnimatedText>Teams & <span className="text-white">Technologies</span></AnimatedText></h2>
                                <h5 className="text-white dmsans-regular text-center team-info"><AnimatedText>Together, we deliver innovation and intelligence through our cutting-edge technologies. Want to join the green squad?</AnimatedText></h5>
                                <AnimatedText>
                                <a
                                    href="https://greenlambda.bamboohr.com/careers" target="_blank"
                                    className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color">
                                    VIEW ALL OPENINGS
                                </a>
                                </AnimatedText>
                            </div>
                            <div className="teams-slider">
                                <img className="w-100" src={GLCTeam.glcTeams} />
                                {/* <Slider {...settings}>
                                {GLCTeam.glcTeams.map((value, index) => {
                                    return (
                                        <div className="slide-item">
                                            <div className={value.sizeClass}>
                                                <div className="d-flex justify-content-center flex-column">
                                                    <div>
                                                        <img src={value.image} />
                                                    </div>
                                                    <div className="team-tag"><h5 className="dmsans-regular">{value.team}</h5></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider> */}
                            </div>
                        </div>
                        <div className="glc-hiring-process">
                            <h2 className="text-white nexa-bold text-center"><AnimatedText>How we <span className="text-primary-green">Hire?</span></AnimatedText></h2>
                            <h5 className="text-white dmsans-regular text-center hiring-text"><AnimatedText>At Green Lambda we care deeply about our teams and the people who make them. We believe diversity of perspectives and experiences can bring the change we truly want. A fair hiring process is the first step to getting there. Here’s how it works:</AnimatedText></h5>
                            <div className="hiring-image">
                                <img className="w-100 d-md-block d-none" src={GLCTeam.glcHiringSteps} />
                                <img className="w-100 d-md-none d-block glcHiringStepsMobile mx-auto" src={GLCTeam.glcHiringStepsMobile} />
                                <div className="text-center">
                                <AnimatedText>
                                    <Link target="_blank" className="btn primary-btn bg-primary-color dmsans-regular text-white border-primary-color hiring-btn me-4" to="https://greenlambda.bamboohr.com/careers">
                                        APPLY NOW
                                    </Link>
                                    </AnimatedText>
                                </div>
                            </div>

                        </div>
                        {/* <div className="glc-jobs">
                        <h2 className="text-white nexa-bold text-center">Jobs <span className="text-primary-green">For You</span></h2>
                        <h5 className="text-white dmsans-regular text-center">Be a part of something special and join a place where talent can thrive.</h5>
                        {GLCTeam.glcJobs.map((value, index) => {
                            return (
                                <div className="job-department">
                                    <h3 className="text-primary-green nexa-bold">{value.department}</h3>
                                    <div className="row jobs-wrapper">
                                        {GLCTeam.glcJobs[index].jobs.map((valueJob, indexJob) => {
                                            return (
                                                <div className="col-lg-3">
                                                    <div className="open-job">
                                                        <h6 className="text-white nexa-bold job-title">{valueJob.title}</h6>
                                                        <p className="text-primary-green">{valueJob.location} </p>
                                                    </div>
                                                </div>

                                            );
                                        })}
                                    </div>

                                </div>
                            )
                        })}

                    </div> */}
                    </div>

                </div>


                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div>
        </>
    );
}