import "./contact-us.scss";
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import React, { useEffect, useRef, useState } from "react";
import HelpCenter from "../help-center/help-center";
import { useLocation } from "react-router-dom";
import LocationsModal from "../locations-modal/locations-modal";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

export default function Contact() {
    const { hash } = window.location;
    const contactRef = useRef(null);
    const demoRef = useRef(null);
    const resellerRef = useRef(null);

    const ContactUs = {
        worldMap: '/company/contact-us/world-map.png',
        worldMapMobile: '/company/contact-us/world-map-mobile.png',
        options: {
            modalName: 'view-addresses',
            title: '<span class="poppins-semi-bold">Our</span> Locations'
        },
    }
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: '426756',
                    formId: 'de3fd2bc-d36f-4d59-b1ab-53c4c6e72e97',
                    target: "#hubspotForm",
                    onFormSubmitted: () => {
                        if (contactRef.current) {
                            contactRef.current.classList.add('d-none');
                          }
                    }
                })
            }
        });
        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: "426756",
                    formId: "8d3d8b92-274a-42c8-97d8-f72cfab22c56",
                    target: "#hubspotDemoForm",
                    onFormSubmitted: () => {
                        if (demoRef.current) {
                            demoRef.current.classList.add('d-none');
                          }
                    }
                })
            }
        });
        script.addEventListener('load', () => {
            // @TS-ignore
            if (window.hbspt) {
                // @TS-ignore
                window.hbspt.forms.create({
                    portalId: "426756",
                    formId: "f69270b8-195c-4a82-a177-a5f608d5ced3",
                    target: "#hubspotSellerForm",
                    onFormSubmitted: () => {
                        if (resellerRef.current) {
                            resellerRef.current.classList.add('d-none');
                          }
                    }
                })
            }
        });
    }, []);
    const requestDemo = useRef();
    useEffect(() => {
        if (hash && hash.slice(1) === "request-demo") {
            requestDemo.current.click();
        }
    }, [hash])


    return (
        <>
            <div>
                <Helmet>
                    <title>Contact Us | Fiber Mountain</title>
                    <meta name="description" content="Get in touch with us to discuss your networking needs or request a demo with our physical layer experts." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="contact-us bg-white">
                {/* Nav Start */}
                <Nav />
                {/* Nav End */}
                <div className="contact-page">
                    <div className="contact-tile-container d-flex justify-content-center">
                        <div className="contact-title-content text-center">
                            <h2 className="contact-title poppins-regular"><span className="poppins-semi-bold">Let's</span> Talk
                            </h2>
                            <h5 className="dmsans-regular text-white contact-subtitle ">We would love to hear from you, get in touch with us now!  </h5>
                        </div>
                    </div>
                    <div className="container">
                        <div className="contact-form-container mx-auto">


                            <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a href="#contact-us" className="nav-link active" id="home-tab" data-bs-toggle="tab" role="tab" aria-controls="home" aria-selected="true">
                                        <h4 className="text-black">Contact Us</h4>
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a href="#request-demo" ref={requestDemo} className="nav-link" id="profile-tab" data-bs-toggle="tab" role="tab" aria-controls="profile" aria-selected="false">
                                        <h4 className="text-black">Request a Demo</h4>
                                    </a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a href="#reseller-form" className="nav-link" id="reseller-tab" data-bs-toggle="tab" role="tab" aria-controls="reseller" aria-selected="true">
                                        <h4 className="text-black">Become a Reseller</h4>
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="contact-us" role="tabpanel" aria-labelledby="home-tab">
                                    <h3 className="poppins-regular text-black form-title text-center" ref={contactRef}>Please fill out this form</h3>
                                    <div className="hubspot-form" id="hubspotForm"></div>
                                </div>
                                <div class="tab-pane fade" id="request-demo" role="tabpanel" aria-labelledby="profile-tab">
                                    <h3 className="poppins-regular text-black form-title text-center" ref={demoRef}>Please fill out this form</h3>
                                    <div className="hubspot-form" id="hubspotDemoForm"></div>
                                </div>
                                <div class="tab-pane fade" id="reseller-form" role="tabpanel" aria-labelledby="reseller-tab">
                                    <h3 className="poppins-regular text-black form-title text-center" ref={resellerRef}>Please fill out this form</h3>
                                    <div className="hubspot-form" id="hubspotSellerForm"></div>
                                </div>

                            </div>
                        </div>
                        <HelpCenter />
                    </div>
                    <div>
                        <div className="container text-center">
                            <h2 class="text-black text-center poppins-regular">Our <span class="text-primary-red">Locations</span></h2>
                            <Link className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color view-apd-btn mt-5" data-bs-target={'#' + ContactUs.options.modalName} data-bs-toggle="modal">
                                View Addresses
                            </Link>
                            <LocationsModal options={ContactUs.options} />
                        </div>
                        <img className="w-100 d-md-block d-none world-map" src={ContactUs.worldMap} />
                        <img className="w-100 d-md-none d-block world-map" src={ContactUs.worldMapMobile} />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}