import "./features.scss";
import AnimatedText from "../animated-text/animated-text";

export default function Features(props) {
    return (
        <>
            <div className="bg-features features-container">
                <div className="container">
                    <AnimatedText>
                        <h2 className="text-white features-main-heading nexa-bold"><span dangerouslySetInnerHTML={{ __html: props.featureHeading }}></span> <span className="text-primary-green">Features</span></h2>
                    </AnimatedText>
                    <div className="row">
                        {props.features.map((value, index) => {
                            return (
                                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                                    <div className="features-elements bg-blue">
                                        <AnimatedText>
                                            <img className="icon" src={value.icon}></img>
                                            <h4 className="text-primary-green features-heading nexa-bold" dangerouslySetInnerHTML={{ __html: value.title }}></h4>
                                            <div className="text-white dmsans-regular">
                                                <p className="dmsans-regular" dangerouslySetInnerHTML={{ __html: value.information }}></p>
                                            </div>
                                        </AnimatedText>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}