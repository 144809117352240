import SolutionAccordion from "../solution-feature-accordion/solution-accordion";
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./layer-one-diagnostics.scss";
import SolutionVideo from "../solution-video/solution-video";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AnimatedText from "../animated-text/animated-text";

export default function UniversalBreakout() {
    const LayerOneDiagnostics = {
        featureTitle: 'Layer One Diagnostics',
        bert: '/solutions/layer-one-diagnostics-assets/bert.png',
        solutionBrief: '/glc-resources/solutionbriefs/Layer One Diagnostics.pdf',
        whiteDownloadIcon: '/products/white-download-icon.svg',
        videoSection: {
            divider: '/solutions/datacenter-colocation-assets/divider.svg',
            icon: '/solutions/layer-one-diagnostics-assets/running-network-devices-icon.png',
            divider1: '/solutions/datacenter-colocation-assets/divider1.svg',
            solutionDiagram: '/solutions/layer-one-diagnostics-assets/how-it-works-diagram.png',
            diagramCaption: "Identify and rectify any layer one errors for maximum connectivity.",
            titleSolid1: 'Keep Your',
            titleStroke: 'Network Devices',
            titleSolid: 'Up and Running'
        },
        features: [
            {
                icon: '/solutions/layer-one-diagnostics-assets/bert-feature-1.svg',
                title: 'Internal & External BERT Tests',
            },
            {
                icon: '/solutions/layer-one-diagnostics-assets/bert-feature-2.svg',
                title: 'Sensus™ Device Diagnosis',
            },
            {
                icon: '/solutions/layer-one-diagnostics-assets/bert-feature-3.svg',
                title: 'Fiber Path<br /> Diagnosis',
            },
            {
                icon: '/solutions/layer-one-diagnostics-assets/bert-feature-4.svg',
                title: 'Port<br /> Inspection',
            },
        ],
        accordionFeatures: [
            {
                image: '/solutions/layer-one-diagnostics-assets/bit-error-ratio-test.png',
                titleGreenPart: 'Bit Error Ratio Test ',
                titleWhitePart: '(BERT)',
                information: 'To run a single port or test the connection between two ports on two Sensus™ devices, the patch panels feature the option to run Bit Error Ratio Tests on device ports.'
            },
            {
                image: '/solutions/layer-one-diagnostics-assets/link-loss-and-continuity-test.png',
                titleGreenPart: 'Continuity',
                titleWhitePart: 'Test',
                information: 'Line continuity and link loss tests can be used to assess functionality and diagnose problems in a fiber path.'
            },
            {
                image: '/solutions/layer-one-diagnostics-assets/health-check.png',
                titleGreenPart: 'Health ',
                titleWhitePart: 'Check',
                information: 'Health Check provides users tools to test Sensus™ device functionality and diagnose problems.'
            },
            {
                image: '/solutions/layer-one-diagnostics-assets/fiber-scope.png',
                titleGreenPart: 'Fiber ',
                titleWhitePart: 'Scope',
                information: 'Users can connect a USB Fiber Scope to the Sensus™ device to inspect the port.'
            },

        ]
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Layer One Diagnostics | Green Lambda Corporation</title>
                    <meta name="description" content="Unleash network speed and agility with Green Lambda's layer one diagnostics solutions: BERT tests, fiber path diagnosis, port inspection, and more." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="layer-one-diagnostics-wrapper bg-blue">
                {/* Capacity Management Hero Section Start */}
                <div className="hero position-relative">
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="hero-content hero-bg d-flex justify-content-center align-items-center flex-column position-relative">
                        {/* <div className="hero-bg"> */}
                        <div className="hero-heading-bg"> <AnimatedText><h1 className="nexa-light text-white text-center">Layer One<div className="nexa-bold text-gradient">Diagnostics</div></h1></AnimatedText> </div>
                        <h5 className="text-white dmsans-regular justify-content-center"><AnimatedText>Green Lambda represents a new way of thinking about data center infrastructure. Layer One Diagnostics solution keeps your network devices up and running at all times, thereby enabling unprecedented network speed and agility.</AnimatedText></h5>
                        <AnimatedText>
                            <Link target="_blank" to={LayerOneDiagnostics.solutionBrief} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color"><i className="fa-solid fa-arrow-down me-2"></i>SOLUTION BRIEF</Link>
                        </AnimatedText>
                        {/* </div> */}
                    </div>
                </div>
                {/* Capacity Management Hero Section Ends */}
                <div className="container bg-blue solution-features-container">
                    <div className="solution-features">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className="nexa-bold text-white text-center"><AnimatedText>Bit Error Ratio Test <span className="nexa-bold text-primary-green">(BERT)</span></AnimatedText></h2>
                            <h5 className="text-white dmsans-regular"><AnimatedText>Sensus™ supports an integral <span className="text-primary-green">Bit Error Ratio Test (BERT)</span> to determine the condition and quality of a selected link. This test can be executed locally via the <span className="text-primary-green">Sensus™ LCD screen </span>or remotely via <span className="text-primary-green">APD</span> to make sure your devices are fully functional.</AnimatedText></h5>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="row">
                                            {LayerOneDiagnostics.features.map((value, index) => {
                                                return (
                                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                                                        <div className="solution-features-elements bg-blue">
                                                            <img className="icon" src={value.icon}></img>
                                                            <AnimatedText><h4 className="text-white features-heading nexa-bold" dangerouslySetInnerHTML={{ __html: value.title }}></h4></AnimatedText>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="offset-lg-1 col-lg-5">
                                        <img className="w-100 m-auto bert-image" src={LayerOneDiagnostics.bert} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Network Visibility Section Starts */}
                    <div className="network-visibility-wrapper">
                        <div className="continuity-bg">
                            <h2 className="text-white nexa-bold"><AnimatedText>Link Loss and <span className="text-primary-green">Continuity Test</span></AnimatedText></h2>
                            <h5 className="text-white dmsans-regular"><AnimatedText><span className="text-primary-green">Line Continuity</span> and <span className="text-primary-green">Link Loss Test</span> evaluates the fiber path continuity. The test enables to determine functionality and <span className="text-primary-green">diagnose issues</span> if they exist within a <span className="text-primary-green">fiber path. </span>It allows users to check functionality and diagnose issues if they exist within a fiber path. This test checks a connection for any polarity issues and measures link loss.</AnimatedText></h5>
                        </div>
                    </div>
                    {/* Network Visibility Section Ends */}
                    <SolutionVideo videoSection={LayerOneDiagnostics.videoSection} />
                    <div className="layer-features">
                        <SolutionAccordion features={LayerOneDiagnostics.accordionFeatures} featureTitle={LayerOneDiagnostics.featureTitle} />
                    </div>
                </div>
                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div >
        </>
    );
}