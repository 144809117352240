import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./all-solutions.scss";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AnimatedText from "../animated-text/animated-text";

export default function AllSolutions() {
    const AllSolutions = {
        physicalSecurity: '/solutions/all-solutions/physical-security.svg',
        physicalSecurityLink: '/solutions/physical-layer-security',
        automatedDocumentationLink: '/solutions/automated-documentation',
        datacenterColocationLink: '/solutions/datacenter-colocation',
        universalBreakoutLink: '/solutions/universal-breakout',
        layerOneDiagnosticsLink: '/solutions/layer-one-diagnostics',
        capacityManagementLink: '/solutions/capacity-management',
        capacityManagement: '/solutions/all-solutions/capacity-management.svg',
        layerOneDiagnostics: '/solutions/all-solutions/layer-one-diagnostics.svg',
        automatedDocumentation: '/solutions/all-solutions/automated-documentation.svg'
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Our Solutions | Green Lambda Corporation</title>
                    <meta name="description" content="Boost network security with physical layer monitoring, automated documentation, data center colocation, breakout devices, diagnostics & capacity management." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="all-solutions-wrapper">
                {/* All Solutions Hero Section Start */}
                <div className="hero position-relative">
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="hero-content hero-bg d-flex justify-content-center align-items-center flex-column position-relative">
                        <div className="solutions-top-left-gradient"></div>
                        <AnimatedText><h1 className="text-center nexa-bold text-gradient">Our Solutions</h1></AnimatedText>
                        <h5 className="dmsans-regular text-white"><AnimatedText>Innovative Solutions for Intelligence and Security in the Physical Layer </AnimatedText></h5>
                    </div>
                </div>
                {/* All Solutions Hero Section Ends */}
                <div className="bg-white">
                    <div className="container solutions-container padding-top-150 padding-bottom-150">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="bg-blue solution-element">
                                    <h2 className="nexa-bold text-white"><AnimatedText>Physical Layer <span className="text-primary-green">Security</span></AnimatedText></h2>
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <h5 className="text-white dmsans-regular physical-solution-info"><AnimatedText>Real-time visibility into every physical layer change ensures that nothing goes unnoticed, enabling a prompt response to any security threat, enhancing network monitoring capabilities.</AnimatedText></h5>
                                            <AnimatedText>
                                                <Link to={AllSolutions.physicalSecurityLink} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color font-weight-700 all-solutions-btn">
                                                    LEARN MORE
                                                </Link>
                                            </AnimatedText>
                                        </div>
                                        <div className="col-lg-7 solution-image-container">
                                            <img className="physical-security-image" src={AllSolutions.physicalSecurity} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                                <div className="bg-white solution-element element-border element-height">
                                    <h2 className="nexa-bold"><AnimatedText>Automated <span className="text-primary-green">Documentation</span></AnimatedText></h2>
                                    <h5 className="dmsans-regular"><AnimatedText>Automatically track your network and record any moves, additions and changes. Eliminating the need for manual documentation and ensuring accurate information.</AnimatedText></h5>
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-6">
                                            <AnimatedText>
                                                <Link to={AllSolutions.automatedDocumentationLink} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color font-weight-700 all-solutions-btn">
                                                    LEARN MORE
                                                </Link>
                                            </AnimatedText>
                                        </div>
                                        <div className="col-lg-6 pt-4 solution-image-container col-sm-6">
                                            <img className="image-width" src={AllSolutions.automatedDocumentation} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <div className="solution-element element-height position-relative data-center-colocation-bg">
                                    <h2 className="nexa-bold text-white colocation-heading"><AnimatedText>Data Center <span className="text-primary-green">Colocation</span></AnimatedText></h2>
                                    <h5 className="dmsans-regular text-white"><AnimatedText>Improve physical layer service request times and minimize human errors in MACs leading to a more reliable and optimized physical layer infrastructure.</AnimatedText></h5>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <AnimatedText>
                                                <Link to={AllSolutions.datacenterColocationLink} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color font-weight-700 all-solutions-btn">
                                                    LEARN MORE
                                                </Link>
                                            </AnimatedText>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 col-xs-12">
                                <div className="solution-element element-height position-relative universal-breakout-bg">
                                    <h2 className="nexa-bold text-white universal-breakout-heading"><AnimatedText>Universal <span className="text-primary-green">Breakout</span></AnimatedText></h2>
                                    <h5 className="dmsans-regular text-white"><AnimatedText>Universal breakout devices for high density switch ports provide easy scalability and flexibility in network configurations.</AnimatedText></h5>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <AnimatedText>
                                                <Link to={AllSolutions.universalBreakoutLink} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color font-weight-700 all-solutions-btn">
                                                    LEARN MORE
                                                </Link>
                                            </AnimatedText>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-6 col-xs-12">
                                <div className="bg-white solution-element element-border element-height">
                                    <h2 className="nexa-bold universal-breakout-heading"><AnimatedText>Layer One <span className="text-primary-green">Diagnostics</span></AnimatedText></h2>
                                    <h5 className="dmsans-regular"><AnimatedText>Health checks that make sure your Sensus™ devices are always up and running. Regular diagnostic assessments & performance evaluations proactively monitor devices.</AnimatedText></h5>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <AnimatedText>
                                                <Link to={AllSolutions.layerOneDiagnosticsLink} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color font-weight-700 all-solutions-btn">
                                                    LEARN MORE
                                                </Link>
                                            </AnimatedText>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="offset-lg-1 col-lg-11 col-xs-12 solution-image-container">
                                            <img className="diagnostics-width" src={AllSolutions.layerOneDiagnostics} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="bg-blue solution-element">
                                    <h2 className="nexa-bold text-white"><AnimatedText>Capacity <span className="text-primary-green">Management</span></AnimatedText></h2>
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <h5 className="text-white dmsans-regular capacity-text"><AnimatedText>Efficient infrastructure and resource planning through port usage visibility and topology visualization allows organizations to optimize network performance, minimize downtime, and ensure seamless connectivity. </AnimatedText></h5>

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-3 col-xs-12">
                                            <AnimatedText>
                                                <Link to={AllSolutions.capacityManagementLink} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color font-weight-700 all-solutions-btn">
                                                    LEARN MORE
                                                </Link>
                                            </AnimatedText>
                                        </div>
                                        <div className="col-lg-9 col-xs-12">
                                            <img className="w-100 capacity-width" src={AllSolutions.capacityManagement} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div>
        </>
    );
}