
import "./solution-video.scss";
import AnimatedText from "../animated-text/animated-text";

export default function SolutionVideo({ videoSection }) {
    return (
        <>
            <img className="w-100 divider-height" src={videoSection.divider} />
            <div className="d-flex solution-info-header justify-content-center flex-column align-items-center text-center">
                <img className="stroke-icon" src={videoSection.icon} />
                <h2 className="nexa-bold">
                    {videoSection.titleSolid1 != '' ? (
                        <div className="text-primary-green">{videoSection.titleSolid1}</div>
                    ) : ''}
                    <div className="stroke-text">{videoSection.titleStroke}</div>
                    <span className="text-primary-green">{videoSection.titleSolid}</span>
                </h2>
            </div>
            <img className="w-100 divider-height" src={videoSection.divider1} />
            <div className="video-wrapper">
                <h2 className="nexa-bold text-center text-white"><AnimatedText>How It <span className="text-primary-green">Works?</span></AnimatedText></h2>
                <AnimatedText><h5 className="dmsans-regular text-white text-center diagram-caption">{videoSection.diagramCaption}</h5></AnimatedText>

                <img className="w-100 solution-diagram" src={videoSection.solutionDiagram} />
                <img className="w-100 divider-height" src={videoSection.divider} />
            </div>

        </>
    );
}