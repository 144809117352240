
import "./solution-accordion.scss";
import { useState } from "react";
import AnimatedText from "../animated-text/animated-text";

export default function SolutionAccordion(props) {
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (

        <>
            <div className="features-accordion">
                <h2 className="text-white nexa-bold"><AnimatedText>{props.featureTitle} <span className="text-primary-green">Features</span></AnimatedText></h2>
                <div className="row features-accordion-container">
                    <div className="col-xl-5 col-lg-6">
                        <div className="accordion" id="accordionExample">

                            {props.features.map((value, index) => {
                                return (
                                    <>

                                        <div className={'accordion-item bg-blue ' + (index === selectedIndex ? 'item-gradient' : 'item-border-right')}>
                                        <div className={(index === selectedIndex ? 'border-top-solid' : '')}></div>
                                            <h4 className="accordion-header" id={"heading" + index} onClick={() => setSelectedIndex(index)}>
                                                <button className="accordion-button nexa-bold text-white" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
                                                    <span><span className={(index === selectedIndex ? 'accordian-heading-green-part' : '')}>{value.titleGreenPart}</span> {value.titleWhitePart}</span>
                                                </button>
                                            </h4>
                                            <div id={"collapse" + index} className={'accordian-collapse collapse ' + (index === selectedIndex ? 'show' : '')} aria-labelledby={"heading" + index} data-bs-parent="#accordionExample">
                                                <div className="accordion-body text-white dmsans-regular">
                                                    <div className="row">
                                                        <div className="col-lg-12 col-sm-6 pt-3">
                                                        {value.information}

                                                        </div>
                                                        <div className="col-sm-6">
                                                        <img className="w-100 m-auto accordion-image-small" src={props.features[index].image} />
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    <div className="offset-xl-1 col-xl-6 col-lg-6 accordion-image my-auto">
                        <img className="w-87" src={props.features[selectedIndex].image} />
                    </div>
                </div>
            </div>
        </>
    );
}