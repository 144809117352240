import SolutionAccordion from "../solution-feature-accordion/solution-accordion";
import SolutionVideo from "../solution-video/solution-video";
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./datacenter-colocation.scss";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AnimatedText from "../animated-text/animated-text";

export default function AllSolutions() {

    const DatacenterColocation = {
        featureTitle: 'Data Center Colocation',
        whiteDownloadIcon: '/products/white-download-icon.svg',
        solutionBrief: '/glc-resources/solutionbriefs/Data Center Colocation.pdf',
        datasheet: '',
        videoSection: {
            animation: '/solutions/datacenter-colocation-assets/animation.json',
            divider: '/solutions/datacenter-colocation-assets/divider.svg',
            icon: '/solutions/datacenter-colocation-assets/added-value.svg',
            divider1: '/solutions/datacenter-colocation-assets/divider1.svg',
            solutionDiagram: '/solutions/datacenter-colocation-assets/how-it-works-diagram.svg',
            diagramCaption: 'No hassle scalability made possible with efficient hardware for effective data center colocation.',
            titleStroke: 'Data Center Colocation',
            titleSolid: 'Adds Value to Your Business',
        },
        features: [
            {
                icon: '/solutions/datacenter-colocation-assets/feature-1.png',
                title: 'Easy and Secure<br /> Network Visibility',
            },
            {
                icon: '/solutions/datacenter-colocation-assets/feature-2.png',
                title: 'Remote Controlled<br /> Cross-connects',
            },
            {
                icon: '/solutions/datacenter-colocation-assets/feature-3.png',
                title: 'Guided<br /> MACs',
            },
            {
                icon: '/solutions/datacenter-colocation-assets/feature-4.png',
                title: 'Layer One<br /> Visibility',
            },
        ],
        accordionFeatures: [
            {
                image: '/solutions/datacenter-colocation-assets/guided-macs.png',
                titleGreenPart: 'Guided ',
                titleWhitePart: 'MACs',
                information: 'The NOC can remotely blink all the LEDs to guide the technician to the device they need to work on, when you utilize the Data Center Colocation solution to guide moves, additions, and changes (MACs). By doing this, your NOC can help navigate the data center using a remote hand.'
            },
            {
                image: '/solutions/datacenter-colocation-assets/intelligent-patch-panels.png',
                titleGreenPart: 'Intelligent ',
                titleWhitePart: 'Patch Panels',
                information: 'Passive by nature, these panels can be managed by Green Lambda’s AllPath® Director software when power is applied. The presence of sensors on all ports, tri-color on all ports, and NFC/RFID are the three primary technologies that enable Green Lambda’s ICID® (Intelligent Connection Identification) on the panel.'
            },
            {
                image: '/solutions/datacenter-colocation-assets/intelligent-cables.png',
                titleGreenPart: 'Intelligent ',
                titleWhitePart: 'Cables',
                information: 'Green Lambda’s ICID® cable clips transform any standard cable into an intelligent cable. The clips keep track of the cable’s life history, including its type, length, manufacture, number of insertions, etc. The clips also support NFC and RFID technology.'
            },
            {
                image: '/solutions/datacenter-colocation-assets/monitored-verification.png',
                titleGreenPart: 'Monitored ',
                titleWhitePart: 'Verification',
                information: 'When the patch is finished, the NOC can remotely verify the work to see whether it was accurate. This enables the NOC to give the remote hand immediate feedback. The colocation system or patching system were not prerequisites in this instance for the remote hand. Instead, the software guided the remote hand throughout the entire work order. This not only shortens the patching process but also lessens the likelihood that a patch would be applied incorrectly due to human mistake.'
            },

        ]
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Data Center Colocation | Green Lambda Corporation</title>
                    <meta name="description" content="Streamline data center colocation with guided MACs, remote cross connects, intelligent patch panels, and simplified network visibility." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="datacenter-colocation-wrapper">
                {/* Datacenter Colocation Hero Section Start */}
                <div className="hero position-relative">
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="hero-content bg-blue d-flex justify-content-center align-items-center flex-column position-relative">
                        <div className="hero-top-right-gradient"></div>
                        <div className="hero-heading-bg">
                            <svg width="807" height="245" id="hero-bg-vector" viewBox="0 0 807 245" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M128.668 2.42235L7.88132 61.0012C5.97058 61.9278 6.63048 64.8007 8.75406 64.8007H250.845C252.971 64.8007 253.629 61.924 251.715 60.9997L130.41 2.42089C129.86 2.15504 129.218 2.15558 128.668 2.42235Z" stroke="#123C4D" stroke-width="3" />
                                <rect x="10.4392" y="73.5256" width="113.709" height="169.285" stroke="#123C4D" stroke-width="3" />
                                <rect x="133.817" y="73.5256" width="113.709" height="169.285" stroke="#123C4D" stroke-width="3" />
                                <rect x="25.5" y="94.1445" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="148.879" y="94.1445" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="25.5" y="117.486" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="148.879" y="117.486" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="25.5" y="140.828" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="148.879" y="140.828" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <circle cx="30.6133" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="153.992" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="55.0665" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="178.445" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="79.5203" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="202.898" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="103.974" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="227.352" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <path d="M402.101 2.42235L281.315 61.0012C279.404 61.9278 280.064 64.8007 282.188 64.8007H524.279C526.404 64.8007 527.062 61.924 525.149 60.9997L403.844 2.42089C403.293 2.15504 402.651 2.15558 402.101 2.42235Z" stroke="#123C4D" stroke-width="3" />
                                <rect x="283.873" y="73.5256" width="113.709" height="169.285" stroke="#123C4D" stroke-width="3" />
                                <rect x="407.251" y="73.5256" width="113.709" height="169.285" stroke="#123C4D" stroke-width="3" />
                                <rect x="298.934" y="94.1445" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="422.312" y="94.1445" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="298.934" y="117.486" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="422.312" y="117.486" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="298.934" y="140.828" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="422.312" y="140.828" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <circle cx="304.047" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="427.426" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="328.5" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="451.879" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="352.954" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="476.332" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="377.407" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="500.785" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <path d="M675.534 2.42235L554.748 61.0012C552.837 61.9278 553.497 64.8007 555.62 64.8007H797.711C799.837 64.8007 800.495 61.924 798.581 60.9997L677.276 2.42089C676.726 2.15504 676.084 2.15558 675.534 2.42235Z" stroke="#123C4D" stroke-width="3" />
                                <rect x="557.306" y="73.5256" width="113.709" height="169.285" stroke="#123C4D" stroke-width="3" />
                                <rect x="680.684" y="73.5256" width="113.709" height="169.285" stroke="#123C4D" stroke-width="3" />
                                <rect x="572.366" y="94.1445" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="695.745" y="94.1445" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="572.366" y="117.486" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="695.745" y="117.486" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="572.366" y="140.828" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <rect x="695.745" y="140.828" width="84.6983" height="10.2267" stroke="#123C4D" stroke-width="2" />
                                <circle cx="577.48" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="700.858" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="601.933" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="725.312" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="626.387" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="749.765" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="650.84" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                                <circle cx="774.218" cy="219.301" r="5.11334" stroke="#123C4D" stroke-width="2" />
                            </svg>

                            <AnimatedText> <h1 className="nexa-light text-white text-center">Data Center <div className="nexa-bold text-gradient">Colocation</div></h1> </AnimatedText></div>
                        <h5 className="text-white dmsans-regular"><AnimatedText>The Green Lambda Data Center Colocation solution reduces human error in MACs and speeds up requests for physical layer services, regardless of whether you manage a sizable colocation data center or are a tenant inside one. Cross connections can be fully automated or guided. Instead of taking weeks, work orders can now be completed in seconds.</AnimatedText></h5>
                        <AnimatedText><Link to={DatacenterColocation.solutionBrief} target="_blank" className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color"><i className="fa-solid fa-arrow-down me-2"></i>SOLUTION BRIEF</Link></AnimatedText>
                    </div>
                </div>
                {/* Datacenter Colocation Hero Section Ends */}
                <div className="bg-blue">
                    <div className="container solution-features-container">
                        <div className="solution-features">
                            <div className="d-flex justify-content-center align-items-center flex-column">
                                <h2 className="nexa-bold text-white text-center"><AnimatedText>Colocation <div className="nexa-bold text-primary-green">Value Proposition</div></AnimatedText></h2>
                                <h5 className="text-white dmsans-regular"><AnimatedText>Most businesses need localized computing but are unable to create their own data centers in each place where they operate. In order to concentrate on their primary area of expertise in business, they also enjoy the idea of outsourcing infrastructure requirements to a third party (such as power, cooling, and physical security). Colocation of data centers is therefore required. <span className="text-primary-green">Companies can just rent space in a data center rather than bearing the whole cost of one.</span></AnimatedText></h5>
                                <div className="container">
                                    <div className="row">
                                        {DatacenterColocation.features.map((value, index) => {
                                            return (
                                                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                                                    <div className="solution-features-elements bg-blue">
                                                        <img className="icon" src={value.icon}></img>
                                                        <AnimatedText> <h4 className="text-white features-heading nexa-bold" dangerouslySetInnerHTML={{ __html: value.title }}></h4></AnimatedText>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Network Visibility Section Starts */}
                        <div className="network-visibility-wrapper">
                            <div className="network-bg">
                                <h2 className="text-white nexa-bold"><AnimatedText>Network Visibility <span className="text-primary-green">Simplified</span></AnimatedText></h2>
                                <h5 className="text-white dmsans-regular"><AnimatedText>With the use of intelligent patch panels and cables, the <span className="text-primary-green">Green Lambda Colocation solution</span> tackles network visibility problems. Our <span className="text-primary-green">intelligent patch panels and cables</span> provide passive connectivity much like conventional patch panels and cables. Their active intelligence is what distinguishes them. The knowledge of your environment is now part of the patch panels and cables and is available to both the remote hand on site and your <span className="text-primary-green">network operations center (NOC)</span> or administrator across the country.</AnimatedText></h5>
                            </div>
                        </div>
                        {/* Network Visibility Section Ends */}
                        <SolutionVideo videoSection={DatacenterColocation.videoSection} />
                        <div className="datacenter-features">
                            <SolutionAccordion features={DatacenterColocation.accordionFeatures} featureTitle={DatacenterColocation.featureTitle} />
                        </div>
                    </div>
                </div>
                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div >
        </>
    );
}