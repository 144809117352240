import "./glc-support.scss";
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import React, { useEffect } from "react";
import HelpCenter from "../help-center/help-center";
import { useState } from "react";

export default function GLCSupport() {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const GLCSupport = {
        warranty: '/company/support/warranty.svg',
        priorityLevels: [
            {
                level: '/company/support/urgent.svg',
                info: 'A production installation of purchased FMI hardware/software is completely inaccessible or most of its functionality is unusable. For low priority issues, please open a support ticket by phone.'
            },
            {
                level: '/company/support/high.svg',
                info: 'One or more important features of purchased FMI hardware/software has become unusable'
            },
            {
                level: '/company/support/medium.svg',
                info: 'Any other case where a feature of purchased FMI hardware/software is not operating as documented'
            },
            {
                level: '/company/support/low.svg',
                info: 'All enhancement requests and general questions.'
            },
        ],
        standardPricingPlans: [
            {
                title: 'Hardware Maintenance',
                details: [
                    {
                        title: 'Support Hours',
                        info: 'M-F, 8am-5pm EST, excluding holidays'
                    },
                    {
                        title: 'Repair Turn Around',
                        info: '5-7 business days',
                    },
                    {
                        title: 'Firmware upgrade',
                        info: 'Minor and patch updates on current version only'
                    }
                ]
            },

            {
                title: 'Software Maintenance',
                details: [

                    {
                        title: 'Support Hours',
                        info: 'M-F, 8am-5pm EST, excluding holidays'
                    },
                    {
                        title: 'Firmware upgrade',
                        info: 'Minor and patch updates on current version only'
                    }
                ]
            }
        ],
        premiumPricingPlans: [
            {
                title: 'Hardware Maintenance',
                details: [
                    {
                        title: 'Support Hours',
                        info: '24 x 7 x 365',
                        bgClass: 'bg-support',

                    },
                    {
                        title: 'Repair Turn Around',
                        info: '5-7 business days',
                        bgClass: 'bg-black',
                    },
                    {
                        title: 'Firmware upgrade',
                        info: 'Major, Minor and Patch updates',
                        bgClass: 'bg-support',
                    }
                ]
            },

            {
                title: 'Software Maintenance',
                details: [

                    {
                        title: 'Support Hours',
                        info: '24 x 7 x 365',
                        bgClass: 'bg-support',
                    },
                    {
                        title: 'Firmware upgrade',
                        info: 'Major, Minor and Patch updates',
                        bgClass: 'bg-support',
                    }
                ]
            }
        ],
        personalHelp: [
            {
                question: 'What is Physical Layer Security?',
                answer: 'The NOC can remotely blink all the LEDs to guide the technician to the device they need to work on, when you utilize the Data Center Colocation solution to guide moves, additions, and changes (MACs). By doing this, your NOC can help navigate the data center using a remote hand.',
            },
            {
                question: 'What features are being offered by GLC?',
                answer: 'The NOC can remotely blink all the LEDs to guide the technician to the device they need to work on, when you utilize the Data Center Colocation solution to guide moves, additions, and changes (MACs). By doing this, your NOC can help navigate the data center using a remote hand.',
            },
            {
                question: 'What Solutions are being offered by GLC?',
                answer: 'The NOC can remotely blink all the LEDs to guide the technician to the device they need to work on, when you utilize the Data Center Colocation solution to guide moves, additions, and changes (MACs). By doing this, your NOC can help navigate the data center using a remote hand.',
            },
            {
                question: 'What are the products of GLC?',
                answer: 'The NOC can remotely blink all the LEDs to guide the technician to the device they need to work on, when you utilize the Data Center Colocation solution to guide moves, additions, and changes (MACs). By doing this, your NOC can help navigate the data center using a remote hand.',
            },
            {
                question: 'What are the solutions offered by Sensus™ patch panel?',
                answer: 'The NOC can remotely blink all the LEDs to guide the technician to the device they need to work on, when you utilize the Data Center Colocation solution to guide moves, additions, and changes (MACs). By doing this, your NOC can help navigate the data center using a remote hand.',
            },
        ],
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="glc-support">
            {/* Nav Start */}
            <Nav />
            {/* Nav End */}
            <div className="contact-page">
                <div className="contact-tile-container d-flex justify-content-center">
                    <div className="contact-title-content text-center">
                        <h2 className="contact-title nexa-light"><span className="text-primary-green nexa-bold">Official GLC </span>Support
                        </h2>
                        <h5 className="dmsans-regular text-white contact-subtitle"></h5>
                    </div>
                </div>
                <div className="text-center support-heading">
                    <h2 className="text-white nexa-bold">How can we <span className="text-primary-green">Help?</span></h2>

                </div>
                <div className="container">
                    <div className="contact-form-container mx-auto">


                        <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true"><h4 className="text-white">Non - Contract Maintenance</h4></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="warranty-tab" data-bs-toggle="tab" data-bs-target="#warranty" type="button" role="tab" aria-controls="warranty" aria-selected="false"><h4 className="text-white">Warranty</h4></button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="pricing-tab" data-bs-toggle="tab" data-bs-target="#pricing" type="button" role="tab" aria-controls="pricing" aria-selected="false"><h4 className="text-white">Pricing Plans</h4></button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <h3 className="nexa-bold text-white form-title text-center">Non-Contract <span className="text-primary-green nexa-bold">Maintenance</span></h3>
                                <div className="support-hours w-80 mx-auto">
                                    <table className="table table-support-hours">
                                        <thead>
                                            <th className="w-50">
                                                <h4><span className="text-primary-green nexa-bold">Support hours</span></h4>
                                            </th>
                                            <th className="w-50">

                                                <h4><span className="text-primary-green nexa-bold">Price </span></h4>

                                            </th>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <h4 className="nexa-bold text-white">8am-5pm EST</h4>
                                                    <p className="incident-info">Normal business hours</p>

                                                </td>
                                                <td>
                                                    <h4 className="nexa-bold text-white">$1500 <span className="incident dmsans-regular">per incident</span></h4>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <h4 className="nexa-bold text-white">8pm-8am EST</h4>
                                                    <p className="incident-info">After business hours</p>

                                                </td>
                                                <td>
                                                    <h4 className="nexa-bold text-white">$2250 <span className="incident dmsans-regular">per incident</span></h4>
                                                </td>

                                            </tr>
                                            <tr>
                                                <td>
                                                    <h4 className="nexa-bold text-white w-40">Weekends / Holidays</h4>


                                                </td>
                                                <td>
                                                    <h4 className="nexa-bold text-white">$3000 <span className="incident dmsans-regular">per incident</span></h4>
                                                </td>

                                            </tr>
                                        </tbody>

                                    </table>


                                </div>
                            </div>
                            <div class="tab-pane fade" id="warranty" role="tabpanel" aria-labelledby="warranty-tab">
                                <h3 className="nexa-bold text-white form-title text-center">Warranty</h3>
                                <div className="warranty">
                                    <div className="row text-center types-container w-80 mx-auto">
                                        <div className="col-lg-6">
                                            <div className="warranty-type d-flex justify-content-center flex-column">
                                                <div className="mb-4">
                                                    <img src={GLCSupport.warranty} />
                                                </div>
                                                <h5 className="nexa-bold text-primary-green">Hardware Basic Warranty</h5>
                                            </div>

                                        </div>
                                        <div className="col-lg-6">
                                            <div className="warranty-type d-flex justify-content-center flex-column">
                                                <div className="mb-4">
                                                    <img src={GLCSupport.warranty} />
                                                </div>
                                                <h5 className="nexa-bold text-primary-green">Software Warranty</h5>
                                            </div>

                                        </div>

                                    </div>
                                    <table className="table table-warranty w-90 mx-auto">
                                        <thead>
                                            <th className="w-25">
                                                <h4><span className="text-primary-green nexa-bold">Priority Level</span></h4>
                                            </th>
                                            <th className="w-75">

                                                <h4><span className="text-primary-green nexa-bold">Description </span></h4>

                                            </th>
                                        </thead>
                                        <tbody>
                                            {GLCSupport.priorityLevels.map((value, index) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            <img src={value.level} />

                                                        </td>
                                                        <td>
                                                            <p className="warranty-info text-white">{value.info}</p>
                                                        </td>

                                                    </tr>
                                                )
                                            })}
                                        </tbody>

                                    </table>

                                </div>
                            </div>
                            <div class="tab-pane fade" id="pricing" role="tabpanel" aria-labelledby="pricing-tab">
                                <h3 className="nexa-bold text-white form-title text-center">Pricing <span className="text-primary-green nexa-bold">Plans</span></h3>
                                <div className="pricing-container">
                                    <div className="row text-center types-container w-90 mx-auto">
                                        <div className="col-lg-6">
                                            <div className="pricing-type d-flex justify-content-center flex-column">
                                                <h3 className="nexa-bold text-primary-green text-center">Standard</h3>
                                                {GLCSupport.standardPricingPlans.map((value, index) => {
                                                    return (
                                                        <>
                                                            <h6 className="text-white text-left nexa-bold my-3">{value.title}</h6>
                                                            <table>
                                                                <tbody>
                                                                    {GLCSupport.standardPricingPlans[index].details.map((value, index) => {
                                                                        return (
                                                                            <tr className={value.bgClass}>
                                                                                <td className="w-45">
                                                                                    <p className="text-primary-green dmsans-regular font-weight-700 pricing-title">{value.title}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <p className="text-white dmsans-regular pricing-info text-left">{value.info}</p>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>

                                                        </>

                                                    )
                                                })}

                                            </div>

                                        </div>
                                        <div className="col-lg-6">
                                            <div className="pricing-type d-flex justify-content-center flex-column">
                                                <h3 className="nexa-bold text-primary-green">Premium</h3>
                                                {GLCSupport.premiumPricingPlans.map((value, index) => {
                                                    return (
                                                        <>
                                                            <h6 className="text-white text-left nexa-bold my-3">{value.title}</h6>
                                                            <table>
                                                                <tbody className="table-border">
                                                                    {GLCSupport.premiumPricingPlans[index].details.map((value, index) => {
                                                                        return (
                                                                            <tr className={value.bgClass}>
                                                                                <td className="w-45">
                                                                                    <p className="text-primary-green dmsans-regular font-weight-700 pricing-title">{value.title}</p>
                                                                                </td>
                                                                                <td>
                                                                                    <p className="text-white dmsans-regular pricing-info text-left">{value.info}</p>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>

                                                        </>

                                                    )
                                                })}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="circle-right"></div> */}

                        {/* <div className="circle-bottom"></div> */}
                    </div>
                    <HelpCenter />
                    <div className="faqs-section">
                        <h2 className="text-center text-white nexa-bold">Frequently asked <span className="text-primary-green">Questions</span></h2>
                        <div className="row">
                            <div className="offset-lg-2 col-lg-8">
                                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active nexa-bold" id="pills-whitepapers-tab" data-bs-toggle="pill" data-bs-target="#pills-whitepapers" type="button" role="tab" aria-controls="pills-whitepapers" aria-selected="true">Personal Help</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link nexa-bold" id="pills-datasheets-tab" data-bs-toggle="pill" data-bs-target="#pills-datasheets" type="button" role="tab" aria-controls="pills-datasheets" aria-selected="false">Business Help</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link nexa-bold" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Technical Help</button>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-whitepapers" role="tabpanel" aria-labelledby="pills-whitepapers-tab">
                                <div className="faqs-accordion">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="accordion" id="accordionExample">

                                                {GLCSupport.personalHelp.map((value, index) => {
                                                    return (
                                                        <>
                                                            <div className={'accordion-item bg-blue ' + (index === selectedIndex ? 'item-gradient' : 'item-border-right')}>
                                                                <h4 className="accordion-header" id={"heading" + index} onClick={() => setSelectedIndex(index)}>
                                                                    <button className="accordion-button nexa-bold text-white" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
                                                                        {value.question}
                                                                    </button>
                                                                </h4>
                                                                <div id={"collapse" + index} className={'accordian-collapse collapse' + (index === selectedIndex ? 'show' : '')} aria-labelledby={"heading" + index} data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body text-white dmsans-regular">
                                                                        {value.answer}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div></div>
                            <div class="tab-pane fade" id="pills-datasheets" role="tabpanel" aria-labelledby="pills-datasheets-tab">...</div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">...</div>
                        </div>
                    </div>

                </div>


            </div>
            <Footer />
        </div>
    );
}