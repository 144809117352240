import SolutionAccordion from "../solution-feature-accordion/solution-accordion";
import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./capacity-management.scss";
import SolutionVideo from "../solution-video/solution-video";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AnimatedText from "../animated-text/animated-text";

export default function CapacityManagement() {
    const CapacityManagement = {
        featureTitle: 'Capacity Management',
        whiteDownloadIcon: '/products/white-download-icon.svg',
        solutionBrief: '/glc-resources/solutionbriefs/Capacity Management.pdf',
        videoSection: {
            divider: '/solutions/datacenter-colocation-assets/divider.svg',
            icon: '/solutions/capacity-management-assets/efficient-and-effective-icon.svg',
            divider1: '/solutions/datacenter-colocation-assets/divider1.svg',
            solutionDiagram: '/solutions/capacity-management-assets/how-it-works-diagram.svg',
            diagramCaption: 'Easily view free ports, available ports and any errors for efficient resource management.',
            titleStroke: 'Effective & Efficient',
            titleSolid: 'Resource Utilization'
        },
        features: [
            {
                icon: '/solutions/capacity-management-assets/planning-feature-1.png',
                title: 'Software Controlled<br/> Management of<br/> Physical Layer',
            },
            {
                icon: '/solutions/capacity-management-assets/planning-feature-2.png',
                title: 'Passive-intelligent<br/> Panels & Cables',
            },
            {
                icon: '/solutions/capacity-management-assets/planning-feature-3.png',
                title: 'Automated<br/> Documentation',
            },
            {
                icon: '/solutions/capacity-management-assets/planning-feature-4.png',
                title: 'Data Center<br/> Colocation',
            },
        ],
        accordionFeatures: [
            {
                image: '/solutions/capacity-management-assets/port-usage-visibility.png',
                titleGreenPart: 'Port Usage ',
                titleWhitePart: 'Visibility',
                information: 'The Green Lambda capacity management solution addresses port usage visibility issues using intelligent patch panels and cables managed via software. Their active intelligence is what makes the port more visible.'
            },
            {
                image: '/solutions/capacity-management-assets/network-topology.png',
                titleGreenPart: 'Network ',
                titleWhitePart: 'Topology',
                information: 'End-to-end connections are visible in the network topologies developed, and APD adds the option to classify and arrange connections as desired.'
            },
            {
                image: '/solutions/capacity-management-assets/resource-planning.png',
                titleGreenPart: 'Resource ',
                titleWhitePart: 'Planning',
                information: 'Green Lambda solutions provide a strategic approach to ensure your resources are used in the most effective way to help your organization achieve maximum efficiency and optimization.'
            },
            {
                image: '/solutions/capacity-management-assets/process-optimization.png',
                titleGreenPart: 'Process ',
                titleWhitePart: 'Optimization',
                information: 'Our solutions will assist you in optimizing network infrastructure procedures by lowering costs while increasing performance, productivity, and efficiency.'
            },

        ]
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Capacity Management | Green Lambda Corporation</title>
                    <meta name="description" content="Optimize network capacity and resource usage with Green Lambda’s software control and intelligent panels that provide visibility for efficient resource planning." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="capacity-management-wrapper bg-blue">
                {/* Capacity Management Hero Section Start */}
                <div className="hero position-relative">
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="hero-content hero-bg d-flex justify-content-center align-items-center flex-column position-relative">
                        {/* <div className="hero-bg"> */}
                        <div className="hero-heading-bg"> <AnimatedText><h1 className="nexa-light text-white text-center">Capacity <div className="nexa-bold text-gradient">Management</div></h1></AnimatedText> </div>
                        <h5 className="text-white dmsans-regular justify-content-center"><AnimatedText>The Green Lambda capacity management solution offers a wide variety of planning actions to make sure that your physical layer infrastructure has adequate resources to maximize its potential activities and production output under any condition. </AnimatedText></h5>
                        <AnimatedText>
                            <Link to={CapacityManagement.solutionBrief} target="_blank" className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color">
                                <i className="fa-solid fa-arrow-down me-2"></i>SOLUTION BRIEF
                            </Link>
                        </AnimatedText>
                        {/* </div> */}
                    </div>
                </div>
                {/* Capacity Management Hero Section Ends */}
                <div className="container bg-blue solution-features-container">
                    <div className="solution-features">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className="nexa-bold text-white text-center"><AnimatedText>Capacity Planning <span className="nexa-bold text-primary-green">Management</span></AnimatedText></h2>
                            <h5 className="text-white dmsans-regular"><AnimatedText>Everyone who manages a network needs to plan for future changes in demands and available technology. With the ability to adjust your network architecture via software, <span className="text-primary-green">Green Lambda</span> offers much-needed flexibility, avoiding the months-long cable wrangling required in today's networks. The <span className="text-primary-green">Green Lambda solution</span> not only automates connectivity to enable network deployment and upgrading, but it also offers <span className="text-primary-green">physical layer security</span> and documentation to give network administrators unprecedented <span className="text-primary-green">visibility and control.</span></AnimatedText></h5>
                            <div className="container">
                                <div className="row">
                                    {CapacityManagement.features.map((value, index) => {
                                        return (
                                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-5">
                                                <div className="solution-features-elements bg-blue">
                                                    <img className="icon" src={value.icon}></img>
                                                    <AnimatedText> <h4 className="text-white features-heading nexa-bold" dangerouslySetInnerHTML={{ __html: value.title }}></h4></AnimatedText>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Network Visibility Section Starts */}
                    <div className="network-visibility-wrapper">
                        <div className="network-bg">
                            <h2 className="text-white nexa-bold"><AnimatedText>Dynamic Network <span className="text-primary-green">Infrastructure</span></AnimatedText></h2>
                            <h5 className="text-white dmsans-regular"><AnimatedText>Strong interconnections that serve east-west traffic make it more difficult to <span className="text-primary-green">expand capacity</span> or move equipment from one part of the system to another. Through software control and automation, <span className="text-primary-green">Green Lambda solutions</span> are intended to increase the dynamic nature of <span className="text-primary-green">physical network structures.</span></AnimatedText></h5>
                        </div>
                    </div>
                    {/* Network Visibility Section Ends */}
                    <SolutionVideo videoSection={CapacityManagement.videoSection} />
                    <div className="capacity-features">
                        <SolutionAccordion features={CapacityManagement.accordionFeatures} featureTitle={CapacityManagement.featureTitle} />
                    </div>
                </div>
                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div >
        </>
    );
}