import "./blog.scss";
import { Link } from "react-router-dom";

export default function Blog() {
    const Blog = {
        blogButton: '/products/blog-button.png'
    }
    return (
        <>
            <div className="row">
                <div className="offset-lg-1 col-lg-10 col-sm-12 col-xs-12 bg-blog blog-container mx-auto">
                    <div className="row">
                        <div className="col-10 col-lg-10 blog-info-container">
                            <h2 className="text-white nexa-bold blog-title">Get in touch with us</h2>
                            <h5 className="dmsans-regular text-white blog-info">Talk about your networking needs with our physical layer experts.</h5>
                        </div>
                        <div className="col-2 col-lg-2 my-auto">
                            <Link to="/contact-us"><img className="blog-button" src={Blog.blogButton}></img></Link>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}