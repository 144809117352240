import Footer from "../footer/footer";
import Nav from "../nav/nav";
import Blog from "../blog/blog";
import Features from "../features/features";
import MoreProducts from "../more-products/more-products";
import "./cables-and-clips.scss";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import VideoModal from "../video-modal/video-modal";
import AnimatedText from "../animated-text/animated-text";

export default function CableAndClips() {

    const CablesAndClips = {
        heroImage: '/products/cables-and-clips-assets/hero.png',
        denseFiberOptics: '/products/cables-and-clips-assets/dense-fiber-optics-cables.png',
        manageInfrastructure: '/products/cables-and-clips-assets/manage-infrastructure.png',
        blogButton: '/products/blog-button.png',
        featureHeading: 'Fiber Optic Cables',
        divider: '/products/product-separator.png',
        datasheet: '/glc-resources/datasheets/Fiber Optic Cables.pdf',
        // whitepaper: '',
        whiteDownloadIcon: '/products/white-download-icon.svg',
        greenDownloadIcon: '/products/green-download-icon.svg',
        APDViewIcon: '/products/cables-and-clips-assets/cables-in-action.svg',
        options: {
            videoLink: 'WFEizBqrqB0',
            modalName: 'ICID-in-action'
        },
        features: [
            {
                icon: '/products/cables-and-clips-assets/feature-1.png',
                title: 'Enhanced<br/> Visibility',
                information: "ICID® (Intelligent Connection Identification) cables work in conjunction with Green Lambda's Sensus™ Panels to provide exceptional visibility into your physical layer."
            },
            {
                icon: '/products/cables-and-clips-assets/feature-2.png',
                title: 'Cable<br/> Jackets',
                information: 'These cables come with UL-rated jackets which include Plenum, Riser, and LSZH jackets to meet any environmental requirement or compliance.'
            },
            {
                icon: '/products/cables-and-clips-assets/feature-3.png',
                title: 'Multi-strand Fiber<br/> Cables',
                information: 'Available in Single or Multimode with A, B, or C polarity and in LC-to-LC, MPO-to-MPO, and MPO-to-LC configurations in both standard and custom lengths.'
            },
            {
                icon: '/products/cables-and-clips-assets/feature-4.png',
                title: 'High<br/> Density',
                information: 'The high quality, bend-insensitive cables are capable of handling high-speed 10Gbps, 25Gbps, 40Gbps, or 100Gbps applications ensuring efficient and consistent data transmission.'
            },
            {
                icon: '/products/cables-and-clips-assets/feature-5.png',
                title: 'Virtual<br/> Topology',
                information: 'ICID® (Intelligent Connection Identification) clips can communicate connectivity information from intelligent patch panels to create a virtual topology of the physical layer.'
            },
            {
                icon: '/products/cables-and-clips-assets/feature-6.png',
                title: 'NFC<br/> Technology',
                information: "All ICID® (Intelligent Connection Identification) cables have NFC-capable connections that pair with Sensus™ NFC-capable management module and integrated NFC reader."
            },

        ],
        products: [
            {
                image: '/products/more-products/sensus.png',
                titleGreenPart: 'Sensus™ - ',
                titleWhitePart: 'Premium Fiber Patch Panel',
                information: "Big data is growing, people and their devices are becoming more connected, and data centers require more and more cable connections. We must control them more effectively! Green Lambda's Sensus™ steps in to help with that. Using intelligent cables and managed with APD software, it solves key physical layer challenges such as visibility and central management.",
                url: '/products/sensus',
            },
            {
                image: '/products/more-products/j-series.png',
                titleGreenPart: 'J- Series ',
                titleWhitePart: 'Breakout Panel',
                information: 'Physical layer serves as the foundation for all network activities. It is, in fact, the most important part of every data center. Introducing the ultimate solution for high-density fiber management in data centers and other mission-critical environments. The J-Series modular breakout cassette is the perfect choice for high-density and flexible fiber management in data centers.',
                url: '/products/j-series',
            },
            {
                image: '/products/more-products/opx.png',
                titleGreenPart: 'OPX<sup class="registered-R">®</sup> -',
                titleWhitePart: 'Optical Path Exchange',
                information: 'A scalable and protocol agnostic software defined cross connect platform that makes your data center management intelligent and hassle free. The OPX® product series allows for automated patching, physical fiber connectivity mesh, network tapping providing network-wide visibility and eliminating signal strength loss.',
                url: '/products/opx',
            },
            {
                image: '/products/more-products/apd.png',
                titleGreenPart: 'APD - ',
                titleWhitePart: 'AllPath<sup class="registered-R">®</sup> Director',
                information: 'A centralized orchestration system called AllPath® Director offers abstracted network control that may be extended to hyperscale data centers. By extending it to layer one, APD makes it possible to manage and create dynamic physical infrastructure while also enabling software-controlled moves, adds, and changes.',
                url: '/products/apd',
            }
        ]
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>Fiber Optic Cables | Green Lambda Corporation</title>
                    <meta name="description" content="Efficient data transmission with Green Lambda's premium fiber optic cables and ICID clips. Our high-quality cables are ideal for diverse cabling needs." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div id="cables-wrapper">
                {/* Nav Section Start */}
                <Nav />
                {/* Nav Section End */}

                {/* Hero Section Start */}
                <div className="hero">
                    <div className="hero-content bg-blue d-flex justify-content-center align-items-center flex-column position-relative">
                        <AnimatedText><h1 className="nexa-bold text-white text-center">Fiber <div> <span className="text-primary-green">Optic Cables</span></div></h1></AnimatedText>
                        <img className="cables-and-clips-hero-image" src={CablesAndClips.heroImage}></img>
                        <div className="cables-and-clips-hero-gradient"></div>
                    </div>
                    {/* Product Header Section Start */}
                    <div className="product-header text-white bg-blue position-relative">
                        <div className="container">
                            <div class="col-12 text-center">
                                <h2 className="cables-and-clips-hero-text nexa-bold"><AnimatedText><span className="text-primary-green font-weight-700">High quality multi-strand fiber cables and ICID<sup className="registered-R">®</sup> (Intelligent Connection Identification) </span>clips that ensure efficient and consistent data transmission.</AnimatedText></h2>
                            </div>
                            <div class="col-12 text-center mt-5">
                                <AnimatedText>
                                    <Link className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2 me-4 action-btn" data-bs-target={'#' + CablesAndClips.options.modalName} data-bs-toggle="modal">
                                        <img class="me-2" src={CablesAndClips.APDViewIcon} alt="APD-360-view-icon" />CABLES in Action
                                    </Link>
                                    <Link to={CablesAndClips.datasheet} target="_blank" className="btn secondary-btn dmsans-regular text-green border-secondary-color mt-2"><i className="fa-solid fa-arrow-down me-2"></i>DATASHEET</Link>
                                    {/* <Link to={CablesAndClips.whitepaper} target="_blank" className="btn secondary-btn dmsans-regular text-green border-secondary-color mt-2"><i className="fa-solid fa-arrow-down me-2"></i>WHITEPAPER</Link> */}
                                </AnimatedText>
                            </div>
                        </div>
                        <div className="cables-and-clips-header-bottom-gradient"></div>
                    </div>
                    {/* Product Header Section End */}
                </div>
                {/* Hero Section End */}



                {/* Product Info Section Start */}
                <div className="bg-white">
                    <div className="container">
                        <div className="product-info bg-blue">
                            <div className="row">
                                <div className="offset-lg-1 col-lg-6 text-white col-sm-12">
                                    <h3 className="dmsans-regular font-weight-700 text-primary-green cables-and-clips-info-text"><AnimatedText>High Density Fiber Optic Cables</AnimatedText></h3>
                                    <h4 className="mt-4 nexa-bold density-explanation">
                                        <AnimatedText> High density multi-strand fiber cables address all data center cabling needs. Capable of handling high-speed 10G, 25G, 40G, or 100G applications. These are available in Single or Multimode with A, B, or C polarity and UL-rated Plenum, Riser, or LSZH jackets.</AnimatedText>
                                    </h4>
                                </div>
                                <div className="col-lg-5 col-sm-12 text-white align-items-center my-auto">
                                    <img className="w-100" src={CablesAndClips.denseFiberOptics}></img>
                                </div>
                            </div>
                        </div>
                        <div className="section-background">
                            <div className="cables-and-clips-info-section connectors-info">
                                <div class="row">
                                    <div className="offset-lg-2 col-lg-8 col-sm-12 text-center">
                                        <h4 className="nexa-bold connectors-variety"><AnimatedText>Green Lambda offers a variety of connector and fiber configurations to meet all cabling demands. <span className="text-primary-green font-weight-700">High quality bend-insensitive fibers ensure efficient and consistent data transmission, </span>while robust LC or MPO connectors minimize insertion and return loss. These ultra high-quality fiber optic cables couple with OPX<sup className="registered-R">®</sup>, Sensus™, and J-Series products to provide superb performance.</AnimatedText></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Product Info Section End */}


                <div className="bg-blue">
                    {/* Cables ICID Section Start  */}
                    <div className="container">
                        <div className="text-center cables-and-clips-connectivity-heading">
                            <h2 className="nexa-bold text-primary-green"><AnimatedText>Cable Clips with ICID<sup className="registered-R">®</sup> (Intelligent Connection Identification) Technology</AnimatedText></h2>
                        </div>
                        <h5 className="dmsans-regular w-70 m-auto px-4 text-white connectivity-explanation"><AnimatedText>Green Lambda's ICID® (Intelligent Connection Identification) clips allow any LC or MPO cable to become discoverable by AllPath® Director (APD) Orchestration Software. <span className="text-primary-green font-weight-700">ICID® (Intelligent Connection Identification) clips can communicate connectivity information from intelligent patch panels </span>to create a virtual topology of the physical layer. To retrofit any system, these can be field installed onto non-ICID cables or third-party cables.</AnimatedText></h5>

                        {/* Blog Section Start */}
                        <Blog />
                        {/* Blog Section End */}

                    </div>
                    {/* Cables ICID Section End  */}

                    {/* Features Section Start */}
                    <Features features={CablesAndClips.features} featureHeading={CablesAndClips.featureHeading} />
                    {/* Features Section End */}

                    {/* Other Products Header Section Start */}
                    <div className="container">
                        <div className="d-flex product-info-header justify-content-center flex-column align-items-center text-center">
                            <img className="d-flex justi" src={CablesAndClips.manageInfrastructure}></img>
                            <h2 className="nexa-bold">
                                <span className="text-primary-green">Manage Your</span>
                                <div>Infrastructure</div>
                                <span className="text-primary-green">By Looking for Free Ports on Devices Easily</span>
                            </h2>
                            <VideoModal options={CablesAndClips.options} />
                        </div>
                        <img className="w-100" src={CablesAndClips.divider}></img>
                        {/* Other Products Header Section End */}

                        {/* More Products Section Start */}
                        <MoreProducts products={CablesAndClips.products} />
                        {/* More Product Section End */}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}