import Footer from "../footer/footer";
import HelpCenter from "../help-center/help-center";
import Nav from "../nav/nav";
import "./events.scss";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Helmet } from 'react-helmet';
import LocationsModal from "../locations-modal/locations-modal";
import AnimatedText from "../animated-text/animated-text";

export default function Events() {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [

            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const eventsAssets = {

        eventGallery: '/company/events/events-gallery.png',
        upcomingEvents: [
            {
                booth: 'BOOTH 3107',
                backgroundImage: '/company/events/upcoming-event-technet.png',
                date: '25-27th June 2024',
                location: 'Baltimore Convention Center, MD',
                title: 'TechNet Cyber 2024',
                description: "Join the Fiber Mountain team at AFCEA’s TechNet Cyber 2024! We are bringing Fiber Mountain solutions, next-gen security, and resilient network innovations to Baltimore. Don't miss this chance to engage with experts and explore cutting-edge technologies.",
                link: 'https://events.afcea.org/AFCEACyber24/Public/enter.aspx'
            },
            {
                booth: 'BOOTH 3107',
                backgroundImage: '/company/events/upcoming-event-technet.png',
                date: '25-27th June 2024',
                location: 'Baltimore Convention Center, MD',
                title: 'TechNet Cyber 2024',
                description: "Join the Fiber Mountain team at AFCEA’s TechNet Cyber 2024! We are bringing Fiber Mountain solutions, next-gen security, and resilient network innovations to Baltimore. Don't miss this chance to engage with experts and explore cutting-edge technologies.",
                link: 'https://events.afcea.org/AFCEACyber24/Public/enter.aspx'
            },

        ],
        wrappedUpEvents: [
            {
                image: '/company/events/wrapped-up-event-1.png',
                title: 'TechCon SoCal 2024',
                description: "Delve into the standout moments from Fiber Mountain's participation at TechCon SoCal! From our engaging exhibition to insightful presentations by our team, we connected with industry leaders, shared valuable insights, and demonstrated our latest technological innovations."

            },
            {
                image: '/company/events/wrapped-up-event-2.png',
                title: 'BICSI Winter Conference and Exhibition 2023',
                description: "Fiber Mountain had an impactful presence at BICSI Winter Conference 2024! Our exhibition and CEO's insightful speaking session were key moments where we connected with industry leaders, shared knowledge, and showcased our cutting-edge products."
            },
            {
                image: '/company/events/wrapped-up-event-3.png',
                title: 'Data Center Summit 2023',
                description: "Team Fiber Mountain captivated attendees with groundbreaking networking innovations. Our CEO’s exclusive insights were met with overwhelming enthusiasm, making it a standout session for advancing industry knowledge and networking solutions."
            }

        ]
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>Events | Fiber Mountain</title>
                    <meta name="description" content="All about Green Lambda's history and ideology. Get to know more about the GLC culture and corporate code of conduct." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="events-wrapper bg-white text-white">
                <div className="hero position-relative">
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="hero-content hero-bg bg-blue d-flex justify-content-center align-items-center flex-column position-relative">
                        <h1 className="poppins-semi-bold text-white text-center hero-heading">Events</h1>
                        <h5 className="dmsans-regular text-white hero-subheading ">Hear from our experts at events all over the world</h5>
                    </div>
                </div>
                <div className="container">
                    {/* Upcoming Events Start */}
                    <div className="upcoming-events-container">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className="poppins-regular text-black upcoming-events-heading">Upcoming Events</h2>
                        </div>
                        <div className="slider-container events-slider">
                            <Slider {...settings}>
                                {eventsAssets.upcomingEvents.map((value, index) => {
                                    return (
                                        <div class="d-flex flex-column event-container position-relative">
                                            <div>
                                                <img className="w-100" src={value.backgroundImage} />
                                            </div>
                                            <div className="content">
                                                <div className="dmsans-regular booth-container">{value.booth}</div>
                                                <div className="dmsans-regular date">{value.date}</div>
                                                <div className="dmsans-regular location">{value.location}</div>
                                                <h4 className="poppins-semi-bold font-weight-600 event-title">{value.title}</h4>
                                                <div className="dmsans-regular event-description">{value.description}</div>
                                                <div className="d-flex btn-flex-container">
                                                    <Link to="" className="btn primary-btn bg-primary-color text-white border-primary-color">
                                                        Schedule a meeting
                                                    </Link>
                                                    <Link to={value.link} className="btn secondary-btn secondary-light-border text-white border-primary-color">
                                                        Learn more
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>
                    {/* Upcoming Events End */}

                    {/* Wrapped Up Events Start */}
                    <div className="wrapped-up-events">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className="poppins-regular text-black wrapped-up-heading">Just Wrapped Up: Recent Events</h2>
                        </div>
                        <div class="d-flex flex-row wrapped-up-container">
                            {eventsAssets.wrappedUpEvents.map((value, index) => {
                                return (
                                    <div className="flex-element">
                                        <div>
                                            <img src={value.image} alt={value.title} />
                                        </div>
                                        <div className="content bg-pink text-black">
                                            <h4 className="poppins-semi-bold font-weight-600 slide-title">{value.title}</h4>
                                            <p className="dmsans-regular">{value.description}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* Wrapped Up Events End */}

                    {/* Wrapped Up Events Start */}
                    <div className="wrapped-up-events">
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <h2 className="poppins-regular text-black wrapped-up-heading">Events Gallery</h2>
                        </div>
                        <img className="w-100" src={eventsAssets.eventGallery} />
                    </div>
                    {/* Wrapped Up Events End */}

                </div>
                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div >
        </>
    );
}