import Footer from "../footer/footer";
import Nav from "../nav/nav";
import Blog from "../blog/blog";
import Features from "../features/features";
import MoreProducts from "../more-products/more-products";
import "./apd.scss";
import DraggableSlider from "./draggable-slider";
import { Link } from "react-router-dom";
import VideoModal from "../video-modal/video-modal";
import { Helmet } from 'react-helmet';
import AnimatedText from "../animated-text/animated-text";

export default function APD() {

    const APD = {
        heroImage: '/products/apd-assets/hero.svg',
        Icon: '/products/apd-assets/icon.png',
        logo: '/products/apd-assets/logo.png',
        agilityUseCases: '/products/apd-assets/agility-use-cases.png',
        blogButton: '/products/blog-button.png',
        featureHeading: 'APD',
        divider: '/products/product-separator.png',
        moreProductImage: '/products/more-products/apd.png',
        datasheet: '/glc-resources/datasheets/AllPath Director.pdf',
        // whitepaper: '',
        whiteDownloadIcon: '/products/white-download-icon.svg',
        greenDownloadIcon: '/products/green-download-icon.svg',
        APDViewIcon: '/products/apd-assets/view-icon.svg',
        options: {
            videoLink: 'HPmzYJ-XTOY',
            modalName: 'apd-in-action'
        },
        features: [
            {
                icon: '/products/apd-assets/feature-1.svg',
                title: 'Global<br/> Data Center View',
                information: 'Organize, manage and view all your deployments no matter where they are in the world. Easy to navigate world map view lets you handle deployments with a few clicks.'
            },
            {
                icon: '/products/apd-assets/feature-2.svg',
                title: 'Interactive<br/> Dashboard',
                information: 'Everything related to your physical layer in one place. End-to-end connection management with software-controlled moves, adds and changes.'
            },
            {
                icon: '/products/apd-assets/feature-3.svg',
                title: 'Custom<br/> Reports',
                information: 'Custom data extraction based on user defined filters and date ranges to view user defined inventory and activity related information.'
            },
            {
                icon: '/products/apd-assets/feature-4.svg',
                title: 'Alarms and<br/> Audit Logs',
                information: 'Alarms Management and Audit trail of all actions taken by users, operators & admins. ICID® (Intelligent Connection Identification) controller for physical layer monitoring.'
            },
            {
                icon: '/products/apd-assets/feature-5.svg',
                title: 'Point and Click<br/> Provisioning',
                information: 'Replication and redundancy for embedded manager, controllers and database.'
            },
            {
                icon: '/products/apd-assets/feature-6.svg',
                title: 'Network Topology<br/> Management',
                information: 'With auto-discovered views that include physical assets and real-time network documentation of entire infrastructure of layer one devices and connections.'
            },
            {
                icon: '/products/apd-assets/feature-7.svg',
                title: 'Diagnostics<br/> Lab',
                information: 'Run tests across your physical layer to test the health of your network devices.'
            },
            {
                icon: '/products/apd-assets/feature-8.svg',
                title: 'Port & Path<br/> Finders',
                information: 'Visualize paths and ports between endpoints to know exactly what is where.'
            },

        ],
        products: [
            {
                image: '/products/more-products/sensus.png',
                titleGreenPart: 'Sensus™ - ',
                titleWhitePart: 'Premium Fiber Patch Panel',
                information: "Big data is growing, people and their devices are becoming more connected, and data centers require more and more cable connections. We must control them more effectively! Green Lambda's Sensus™ steps in to help with that. Using intelligent cables and managed with APD software, it solves key physical layer challenges such as visibility and central management.",
                url: '/products/sensus',
            },
            {
                image: '/products/more-products/j-series.png',
                titleGreenPart: 'J- Series ',
                titleWhitePart: 'Breakout Panel',
                information: 'Physical layer serves as the foundation for all network activities. It is, in fact, the most important part of every data center. Introducing the ultimate solution for high-density fiber management in data centers and other mission-critical environments. The J-Series modular breakout cassette is the perfect choice for high-density and flexible fiber management in data centers.',
                url: '/products/j-series',
            },
            {
                image: '/products/more-products/opx.png',
                titleGreenPart: 'OPX<sup class="registered-R">®</sup> -',
                titleWhitePart: 'Optical Path Exchange',
                information: 'A scalable and protocol agnostic software defined cross connect platform that makes your data center management intelligent and hassle free. The OPX® product series allows for automated patching, physical fiber connectivity mesh, network tapping providing network-wide visibility and eliminating signal strength loss.',
                url: '/products/opx',
            },
            {
                image: '/products/more-products/cable-and-clips.png',
                titleGreenPart: 'Fiber Optic',
                titleWhitePart: 'Cables',
                information: 'High quality multi-strand fiber cables and ICID® (Intelligent Connection Identification) clips that ensure efficient data transmission and ensure all data center communication needs. Capable of handling high-speed 10G, 25G, 40G, or 100G applications. These are available in Single or Multimode with A, B, or C polarity and UL-rated Plenum, Riser, or LSZH jackets.',
                url: '/products/fiber-optic-cables',
            },
        ],
        cdnControlSider1: [
            {
                src: '/products/apd-assets/slide1.png',
            },
            {
                src: '/products/apd-assets/slide2.png',
            },
            {
                src: '/products/apd-assets/slide3.png',
            },
        ],
        cdnControlSider2: [
            {
                src: '/products/apd-assets/slide4.png',
            },
            {
                src: '/products/apd-assets/slide5.png',
            },
            {
                src: '/products/apd-assets/slide6.png',
            },
        ]
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>APD - AllPath® Director | Green Lambda Corporation</title>
                    <meta name="description" content="Transform your physical layer with Green Lambda’s AllPath® Director. Gain control, agility, and visibility into your network to make it secure." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="apx-wrapper">
                {/* Nav Start */}
                <Nav />
                {/* Nav End */}

                {/* APD Hero Section Start */}
                <div className="hero position-relative">
                    <div className="hero-content bg-blue d-flex justify-content-center align-items-center flex-column position-relative">
                        <img className="apd-hero-image" src={APD.heroImage}></img>
                        <AnimatedText><h1 className="nexa-bold text-white text-center">Physical Layer <div> Management with</div><div> <span className="text-primary-green mr-3">AllPath<sup className="registered-R">®</sup>Director</span></div></h1></AnimatedText>
                    </div>
                    <div className="apd-left-gradient"></div>
                </div>
                {/* APD Hero Section End */}

                {/* APD Info Section Start */}
                <div className="bg-blue">

                    <div className="container">
                        <h5 className="dmsans-regular apd-info text-white">
                            <AnimatedText> A key Green Lambda solution, the <span className="text-primary-green">AllPath® Director</span> is a centralized orchestration system providing abstracted network control to enterprise and hyperscale data centers. AllPath® Director makes use of physical layer device discovery, ICID® (Intelligent Connection Identification), per-port LED control and high-density fiber optics to extend control to layer one.</AnimatedText>
                        </h5>
                        <div class="col-12 text-center mt-5">
                            <AnimatedText>
                                <Link className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color view-apd-btn mt-2 me-4 action-btn" data-bs-target={'#' + APD.options.modalName} data-bs-toggle="modal">
                                    <img class="me-2" src={APD.APDViewIcon} alt="APD-360-view-icon" />APD in Action
                                </Link>
                                <Link to={APD.datasheet} target="_blank" className="btn secondary-btn dmsans-regular text-green border-secondary-color mt-2"><i className="fa-solid fa-arrow-down me-2"></i>DATASHEET</Link>
                                {/* <Link to={APD.whitepaper} className="btn secondary-btn dmsans-regular text-green border-secondary-color mt-2"><i className="fa-solid fa-arrow-down me-2"></i>WHITEPAPER</Link> */}
                            </AnimatedText>
                        </div>

                        {/* APD SDN Control Slider Start */}
                        <div className="d-flex justify-content-center align-items-center flex-column sdn-control-slider">
                            <img className="apd-logo-width" src={APD.logo} />
                            <h2 className="text-white nexa-bold text-center sdn-control-heading"><AnimatedText>Software <span className="text-primary-green">Controlled</span> <div>Physical <span className="text-primary-green">Connectivity</span></div></AnimatedText></h2>
                            <h4 className="dmsans-regular apd-info m-auto text-white sdn-control-padding">
                                <AnimatedText>Programmatic control via AllPath® Director is unique because it enables software-based control of physical connectivity. The solution can be implemented as an entire network managed by AllPath® Director, or integrated with existing network architectures to add agility and visibility in a wide range of use cases.</AnimatedText>
                            </h4>
                        </div>
                    </div>
                    <DraggableSlider images={APD.cdnControlSider1} addedClass='slider1' />
                    <DraggableSlider images={APD.cdnControlSider2} addedClass='slider2' />
                    {/* APD SDN Control Slider End */}

                    <div className="container">
                        {/* APD Agility Use Cases Start */}
                        <div className="d-flex justify-content-center align-items-center flex-column agility-use-cases position-relative">
                            <div className="apd-agility-middle-gradient"></div>
                            <AnimatedText><h2 className="text-white nexa-bold">Agility Use Cases</h2></AnimatedText>
                            <img className="agility-diagram-padding w-100" src={APD.agilityUseCases} />
                            <h4 className="nexa-bold apd-info m-auto text-white">
                                <AnimatedText> The AllPath<sup className="registered-R">®</sup> Director architecture accommodates rapid development and integration of new features and functions which is a significant benefit for data centers to virtualize network functions.</AnimatedText>
                            </h4>
                        </div>
                        {/* APD Agility Use Cases End */}

                        {/* Blog Section Start */}
                        <Blog />
                        {/* Blog Section End */}
                    </div>

                    {/* Features Section Start */}
                    <Features features={APD.features} featureHeading={APD.featureHeading} />
                    {/* Features Section End */}

                    <div className="container">
                        <div className="d-flex product-info-header justify-content-center flex-column align-items-center text-center">
                            <img src={APD.moreProductImage}></img>
                            <h2 className="nexa-bold">
                                <span className="text-primary-green">Revolutionize Your</span>
                                <div>Physical Layer</div>
                                <span className="text-primary-green">With Our Integrated Solutions</span>
                            </h2>
                            <AnimatedText>
                                <Link className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color view-apd-btn mt-5" data-bs-target={'#' + APD.options.modalName} data-bs-toggle="modal">
                                    <img class="me-2" src={APD.APDViewIcon} alt="APD-360-view-icon" />APD in Action
                                </Link>
                            </AnimatedText>
                            <VideoModal options={APD.options} />
                        </div>
                        <img className="w-100" src={APD.divider}></img>
                        {/* More Products Section Start */}
                        <MoreProducts products={APD.products} />
                        {/* More Product Section End */}
                    </div>
                </div>
                {/* APD Info Section End */}

                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div>
        </>
    );
}