import "./home.scss";
import Nav from "../nav/nav";
import Footer from "../footer/footer";
import GLCExperience from "../glc-experience/glc-experience";
import { constants } from "../../constants";
import { Link } from "react-router-dom";
import AnimatedText from "../animated-text/animated-text";
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
export default function Home() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const homeAssets = {
    heroImageBg: '/backgrounds/home-bg.png',
    homeSolutionsBg: '/backgrounds/home-solutions-bg.svg',
    enterpriseIcon: '/solutions/icons/enterprise-icon-black.png',
    federalIcon: '/solutions/icons/federal-icon-black.png',
    networkAutomation: '/expertise/network-operation-automation.png',
    allProductsURL: '/all-products',
    glcProducts: [
      {
        icon: '/products/apd-screen-vision.png',
        tag: 'JTIC Certified Software',
        name: 'AllPath® Director Vision',
        info: 'Network Management Software that automates the documentation & management of patch panels with an added layer of security, custom-made for mission-critical environments.',
        link: '/products/apd'

      },
      {
        icon: '/products/simplphy-screen-icon.png',
        tag: 'SaaS Platform',
        name: 'SiMPLphy',
        info: 'Advanced Saas-based connectivity documentation and management platform that optimizes the management of Data Centers.',
        link: '/products/sensus'

      },
      {
        icon: '/products/apd-screen-starling.png',
        tag: 'Flagship Software',
        name: 'AllPath® Director Starling',
        info: 'Network Management Software that automates the documentation & management of patch panels, tailored for Enterprise Networks.',
        link: '/products/apd'

      }],
    glcFederalSolutions: [

      {
        name: 'Neque porro quisquam est',
        info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
        link: ''

      },
      {
        name: 'Neque porro quisquam est',
        info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
        link: ''

      },
      {
        name: 'Neque porro quisquam est',
        info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
        link: ''

      }
    ],
    glcEnterpriseSolutions: [

      {
        name: 'Neque porro quisquam est',
        info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
        link: ''

      },
      {
        name: 'Neque porro quisquam est',
        info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
        link: ''

      },
      {
        name: 'Neque porro quisquam est',
        info: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy",
        link: ''

      }
    ],
    resourcesSlides: [
      {
        img: "/home-resources-slides/resource-slide-1.png",
        title: "What is the Fiber Mountain Solution?",
        description: "Discover how Fiber Mountain simplifies re-configuring infrastructure connections, providing operational benefits and reducing costs.",
        link: ""
      },
      {
        img: "/home-resources-slides/resource-slide-2.png",
        title: "Fiber Mountain's Zero Trust Managed Patching Solution ",
        description: "Read how Fiber Mountain's managed patching solution marks a significant advancement in data center security.",
        link: ""
      },
      {
        img: "/home-resources-slides/resource-slide-3.png",
        title: "AllPath® Director | Network Management Software",
        description: "A key component of the Fiber Mountain solution, AllPath® Director is a network management software that streamlines data center management.",
        link: ''
      },
      {
        img: "/home-resources-slides/resource-slide-4.png",
        title: "Sensus™ Premium Fiber Patch Panel",
        description: "Read how Sensus™ patch panel and Fiber Mountain solutions innovate to efficiently manage escalating data and cable demands.",
        link: ""
      },
      {
        img: "/home-resources-slides/resource-slide-1.png",
        title: "Physical Layer 2.0 | Secure and Intelligent",
        description: "To enhance data center agility, we reimagine the physical layer with integrated security and intelligence, the cornerstone of network activity.",
        link: ""
      },

    ]
  }
  return (


    <>
      <div>
        <Helmet>
          <title>Data Center Experts | Fiber Mountain</title>
          <meta name="description" content="Fiber Mountain specializes in hardware and software solutions for physical layer security and intelligence." />
          {/* Add more meta tags as needed */}
        </Helmet>
      </div>
      <div className="bg-white">
        <Nav />
        <div className="diagram-bg">
          <div className="container">
            {/* GLC Architecture Diagram Section */}
            <div className="glc-architecture-diagram">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <h1 className="text-white z-index-1 banner-heading poppins-regular">
                    We <span className="font-weight-600">Secure</span> and <span className="font-weight-600">Automate</span> Data Centers with Cutting-Edge <span className="font-weight-600">Software</span> Solutions </h1>
                  <div className="diagram mt-5">
                  </div>
                </div>
              </div>
            </div>
            {/* GLC Architecture Diagram Section End */}
          </div>
        </div>
      </div >
      <div className="bg-white">
        <div className="container">
          {/* GLC Demo Section */}
          <div className="glc-demo text-white">
            <div className="segmentation-container">
              <div className="segmentation-bg" >
                <div className="d-flex flex-column">
                <div className="flex-item">
                  <img className="icon-size" src={homeAssets.enterpriseIcon} />
                  </div>
                  <div className="flex-item fill">
                    <h2 className="poppins-bold text-blue-primary glc-demo-heading">
                      Enterprise Solutions
                    </h2>
                    <h5 className="glc-demo-info dmsans-regular">
                      State-of-the-art products aimed at optimizing business operations and growth, with a focus on Security, Serviceability, and Workflow Automation.
                    </h5>
                  </div>
                  {/* <AnimatedText> */}
                  <div className="flex-item">
                    <a className="btn secondary-btn bg-primary-color text-white border-primary-color mt-auto" href="/contact-us/#request-demo">
                      Learn more
                    </a>
                    </div>

                  {/* </AnimatedText> */}
                </div>

              </div>
              <div className="segmentation-bg">
                <div className="d-flex flex-column flex-container">
                  <div className="flex-item">
                    <img className="icon-size" src={homeAssets.federalIcon} />
                  </div>
                  <div className="flex-item fill">
                    <h2 className="poppins-bold text-blue-primary glc-demo-heading">
                      Federal Solutions
                    </h2>
                    <h5 className="glc-demo-info dmsans-regular">
                      Secure, efficient, and resilient solutions for mission-critical environments.
                    </h5>
                  </div>
                  <div className="flex-item">
                    <a className="btn secondary-btn bg-primary-color text-white border-primary-color mt-auto align-self-end" href="/contact-us/#request-demo">
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* GLC Demo Section End */}

        {/* GLC Benefits Section */}
        <div className="glc-benefit">
          <div className="glc-products">
            <div className="container">
              <h2 className="glc-products-heading poppins-regular text-blue-primary">
                Secure and <span className="font-weight-600">Optimize</span> Your <span className="font-weight-600">Global</span> <span className="font-weight-600">Networks</span> with Us
              </h2>
            </div>
            <div className="partners-background">
              <div className="partners-grid">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-9">
                      <h5 className="text-black dmsans-regular text-product">Trusted by top Fortune 500 companies and federal government agencies, Fiber Mountain is a leading product company that develops integrated software and hardware solutions to optimize data center management. Our integrated approach empowers you to seamlessly track, monitor, and control your data center operations with unmatched precision and efficiency.</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* GLC Benefits Section End */}
        {/* Expertise Section Start */}
        <div className="bg-pink expertise-section">
          <div className="container">
            <h2 className="poppins-regular text-center  text-blue-primary expertise-heading">Explore Our <span className="font-weight-600">Expertise</span></h2>

            <div className="expertise-grid text-white">
              <div className="column column-one">
                <img src={homeAssets.networkAutomation} />
                <div className="expertise-content">
                  <h2 className="poppins-regular font-weight-600 expertise-subheading">Network Operations Automation</h2>
                  <h5 className="dmsans-regular text-product">
                    Manual network management is time-consuming and prone to errors. Our products automate your network operations, enhancing efficiency and cutting operational costs. This automation enables improved data center administration and faster response to network changes and security concerns. </h5>
                </div>
              </div>
              <div className="column column-two">
                <div className="grid-two">
                  <div className="grid-two-column-one">
                    <h2 className="poppins-regular font-weight-600 ">Port & User-Level Security </h2>
                    <h5 className="dmsans-regular text-product">
                    In today’s evolving threat landscape, our zero-trust products stand out by offering unparalleled security never seen before. With port locking and user-level controls, we rigorously authenticate and authorize, providing unmatched protection and network-wide visibility to safeguard your digital assets. </h5>
                  </div>
                  <div className="grid-two-column-two">
                    <h2 className="poppins-regular font-weight-600 ">AI-Powered Analytics</h2>
                    <h5 className="dmsans-regular text-product">
                      We leverage AI you can trust to transform your data into actionable insights, ensuring secure and transparent operations. Our solutions analyze data in real-time, providing comprehensive visibility of your network’s performance. This helps in making informed decisions, resource optimization, and foreseeing future challenges. </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white glc-solutions-home-container">
          <AnimatedText>
            <h2 className="fm-solutions-heading poppins-regular text-black text-center">
              Tough challenges? Tailored solutions.
            </h2>
          </AnimatedText>
          <div className="home-solutions-bg">
            {/* <img src={homeAssets.homeSolutionsBg}/> */}
            <div className="container">
              <div className="d-flex solutions-flex-container m-auto w-90">
                <div className="bg-pink solutions-element">
                  <div className="d-flex justify-content-center">
                    <span className="badge poppins-regular">Federal Solutions</span>
                  </div>

                  {homeAssets.glcFederalSolutions.map((value, index) => {
                    return (
                      <div className="text-black">
                        <div className="flex-grow-1">
                          <AnimatedText>
                            <span className="badge poppins-regular">{value.tag}</span>
                          </AnimatedText>
                        </div>
                        <div className="flex-grow-1">
                          <AnimatedText>
                            <h4 className="poppins-regular text-element"> <span dangerouslySetInnerHTML={{ __html: value.name }}></span></h4>
                          </AnimatedText>
                        </div>

                        <div className="flex-grow-1">
                          <AnimatedText>
                            <p className="solution-text dmsans-regular">{value.info}</p>
                          </AnimatedText>
                        </div>
                        <div className="flex-grow-1">
                          <Link to={value.link} className="poppins-bold solutions-link bg-primary-color border-primary-color btn-read-more">See More</Link>
                        </div>
                        <hr className="hr-footer hr-solutions"></hr>
                      </div>
                    )
                  })}
                </div>
                <div className="bg-pink solutions-element">
                  <div className="d-flex justify-content-center">
                    <span className="badge poppins-regular">Enterprise Solutions</span>
                  </div>

                  {homeAssets.glcEnterpriseSolutions.map((value, index) => {
                    return (
                      <div className="text-black">
                        <div className="flex-grow-1">
                          <AnimatedText>
                            <span className="badge poppins-regular">{value.tag}</span>
                          </AnimatedText>
                        </div>
                        <div className="flex-grow-1">
                          <AnimatedText>
                            <h4 className="poppins-regular text-element"> <span dangerouslySetInnerHTML={{ __html: value.name }}></span></h4>
                          </AnimatedText>
                        </div>

                        <div className="flex-grow-1">
                          <AnimatedText>
                            <p className="solution-text dmsans-regular">{value.info}</p>
                          </AnimatedText>
                        </div>
                        <div className="flex-grow-1">
                          <Link to={value.link} className="poppins-bold solutions-link bg-primary-color border-primary-color btn-read-more">See More</Link>
                        </div>
                        <hr className="hr-footer hr-solutions"></hr>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Expertise Section End */}
        <div className="bg-white glc-products-home-container">
          <div className="container">
            <AnimatedText>
              <h2 className="glc-benefit-heading poppins-regular text-black">
                Featured Products
              </h2>
            </AnimatedText>
            <div className="row text-center w-100">
              {homeAssets.glcProducts.map((value, index) => {
                return (
                  <div className="col-lg-4 col-sm-4">
                    <div className="product-element bg-pink text-center text-black">
                      <div className="flex-grow-1">
                        <AnimatedText>
                          <img className="image-margin" src={value.icon} />
                        </AnimatedText>
                      </div>
                      <div className="flex-grow-1">
                        <AnimatedText>
                          <span className="badge poppins-regular">{value.tag}</span>
                        </AnimatedText>
                      </div>
                      <div className="flex-grow-1">
                        <AnimatedText>
                          <h4 className="poppins-semi-bold font-weight-600 text-element"> <span dangerouslySetInnerHTML={{ __html: value.name }}></span></h4>
                        </AnimatedText>
                      </div>
                      <div className="flex-grow-1">
                        <AnimatedText>
                          <p className="product-text dmsans-regular">{value.info}</p>
                        </AnimatedText>
                      </div>
                      <div className="flex-grow-1">
                        <AnimatedText>
                          <Link to={value.link} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2 btn-read-more">LEARN MORE</Link>
                        </AnimatedText>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {/* Expertise Section End */}
        {/* Resource Section Start */}
        <div className="bg-white resource-container">
          {/* <div className="container"> */}
          <AnimatedText>
            <h2 className="fm-resource-heading poppins-regular text-center text-black">
              Resource Library
            </h2>
          </AnimatedText>
          <div className="row text-center w-100">
            <div className="slider-container">
              <Slider {...settings}>
                {homeAssets.resourcesSlides.map((value, index) => {
                  return (
                    <div class="d-flex flex-column">
                      <div>
                     <img src={value.img}/>
                     </div>
                     <div className="content bg-pink">
                     <h4 className="poppins-semi-bold font-weight-600 slide-title">{value.title}</h4>
                     <p className="product-text dmsans-regular">{value.description}</p>
                     </div>
                    </div>

                  )
                })}

              </Slider>
            </div>
          </div>
          {/* </div> */}
        </div>
        {/* Resource Section End */}
      </div >
      <Footer />
    </>
  );
}
