import Footer from "../footer/footer";
import Nav from "../nav/nav";
import "./privacy-policy.scss";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
    // Define constants for privacy policy content
    const GREEN_LAMBDA_MISSION = 'Green Lambda™ is committed to transforming network infrastructure, and to providing you with the information you need to make educated decisions about the continuing evolution of your data centers.';

    const DATA_COLLECTION_DESCRIPTION = 'To do this, we constantly create new content, whether in the form of blog posts, technical data sheets, or downloadable eBooks, and we also gather and analyze a variety of data that helps us to understand what content is most useful to our visitors and how you prefer to engage with us. We appreciate the trust you show by sharing your information with us and promise to respect your privacy.';

    const EMAIL_USAGE_DESCRIPTION = 'Green Lambda uses information you provide, such as your email address, in order to send you information you request, as well as other Green Lambda communications that may interest you. We will also work to personalize and optimize your experience of our website, to better understand how our products can meet your needs, and to put you in touch with our channel partners or resellers when you are ready to buy.';

    const OPT_OUT_DESCRIPTION = 'You can always opt out of further emails by using the opt-off link at the bottom of every email or by emailing <a class="text-url" href="mailto:info@greenlambda.com">info@greenlambda.com</a> with “Unsubscribe” in the subject line and a list of the email addresses you want to opt off.';

    const THIRD_PARTY_DESCRIPTION = 'In addition to the information you submit to us directly, we use third-party services such as Google Analytics and Hubspot to track activity on greenlambda.com and analyze which content you find most useful.';

    const COOKIE_ALERT = 'These services use cookies in order to gather data for us, which you can control via your browser settings.'
    const LAW_DECLARATION = 'The only other times Green Lambda™ will share your personal data with a third party are if required by law, or if Green Lambda™ is acquired by another company, in which case we will bring our database along in order to keep providing the personal experience you have come to expect.'
    const PRIVACY_DIVIDER = '/company/privacy-divider.png';

    return (
        <div className="error-wrapper bg-blue">
            <div className="hero position-relative">
                {/* Nav Start */}
                <Nav />
                {/* Nav End */}
                <div className="privacy-wrapper container">
                    <div className="privacy-policy-right-gradient"></div>
                    <div className="privacy-policy-left-gradient"></div>
                    <h1 className="nexa-bold text-white text-center">Privacy <span className="text-primary-green">Policy</span></h1>
                    <img className="w-100" src={PRIVACY_DIVIDER} />
                    <h2 className="nexa-bold text-white">Our mission, your privacy</h2>
                    <h5 className="dmsans-regular text-white">{GREEN_LAMBDA_MISSION}</h5>
                    <h5 className="dmsans-regular text-white">{DATA_COLLECTION_DESCRIPTION}</h5>
                    <h2 className="nexa-bold text-white">We will never sell your information!</h2>
                    <h5 className="dmsans-regular text-white">{EMAIL_USAGE_DESCRIPTION}</h5>
                    <h5 className="dmsans-regular text-white" dangerouslySetInnerHTML={{__html: OPT_OUT_DESCRIPTION}}></h5>
                    <h5 className="dmsans-regular text-white">{THIRD_PARTY_DESCRIPTION}</h5>
                    <h5 className="dmsans-regular text-white">{COOKIE_ALERT}</h5>
                    <h5>
                        <Link target="_blank" className="external-link" to="https://analytics.google.com/analytics/web/provision/?authuser=0#/provision">Read more about how Google Analytics works </Link>
                    </h5>
                    <h5>
                        <Link target="_blank" className="external-link" to="https://www.aboutcookies.org.uk/managing-cookies">Read more about managing your cookies</Link>
                    </h5>
                    <h5 className="dmsans-regular text-white">{LAW_DECLARATION}</h5>
                </div>

            </div>
            {/* Capacity Management Hero Section Ends */}
            {/* Footer Start */}
            <Footer />
            {/* Footer End */}
        </div >
    );
}