import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo from './logo.svg';
import './App.css';
import './sassStyles/_global.scss';
import Home from './components/home/home';
import AllProducts from './components/all-products/all-products';
import AllSolutions from './components/all-solutions/all-solutions';
import Events from './components/events/events';
import APD from './components/apd/apd';
import JSeries from './components/j-series/j-series';
import OPX from './components/opx/opx';
import Sensus from './components/sensus/sensus';
import CableAndClips from './components/cables-and-clips/cables-and-clips';
import DatacenterColocation from './components/datacenter-colocation/datacenter-colocation';
import CapacityManagement from './components/capacity-management/capacity-management';
import AutomatedDocumentation from './components/automated-documentation/automated-documentation';
import PhysicalLayerSecurity from './components/physical-layer-security/physical-layer-security';
import UniversalBreakout from './components/universal-breakout/universal-breakout';
import LayerOneDiagnostics from './components/layer-one-diagnostics/layer-one-diagnostics';
import AboutUs from './components/about-us/about-us';
import GLCTeam from './components/glc-team/glc-team';
import Resources from './components/resources/resources';
import ContactUs from './components/contact-us/contact-us';
import GLCSupport from './components/glc-support/glc-support';
import NotFound from './components/error-404/error-404';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/scroll-to-top/scroll-to-top';
import PrivacyPolicy from './components/privacy-policy/privacy-policy';
import EULA from './components/EULA/EULA';
import Sitemap from './components/sitemap/sitemap';
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route exact path="/" element={<Home />} />

          {/* Products Routes Start */}
          <Route exact path="/all-products" element={<AllProducts />} />
          <Route exact path="/products/apd" element={<APD />} />
          <Route exact path="/products/j-series" element={<JSeries />} />
          <Route exact path="/products/opx" element={<OPX />} />
          <Route exact path="/products/sensus" element={<Sensus />} />
          <Route exact path="/products/fiber-optic-cables" element={<CableAndClips />} />
          {/* Products Routes End */}

          {/* Solutions Routes Start*/}
          <Route exact path="/all-solutions" element={<AllSolutions />} />
          <Route exact path="/solutions/datacenter-colocation" element={<DatacenterColocation />} />
          <Route exact path="/solutions/capacity-management" element={<CapacityManagement />} />
          <Route exact path="/solutions/physical-layer-security" element={<PhysicalLayerSecurity />} />
          <Route exact path="/solutions/automated-documentation" element={<AutomatedDocumentation />} />
          <Route exact path="/solutions/universal-breakout" element={<UniversalBreakout />} />
          <Route exact path="/solutions/layer-one-diagnostics" element={<LayerOneDiagnostics />} />
          {/* Solutions Routes End */}

          {/* Company Routes Start */}
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/glc-team" element={<GLCTeam />} />
          <Route exact path="/resources" element={<Resources />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/glc-support" element={<GLCSupport />} />
          <Route exact path="/events" element={<Events />} />
          {/* Company Routes End */}

          {/* Other Pages Start */}
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/eula" element={<EULA />} />
          <Route exaxt path="/sitemap" element={<Sitemap/>} />
          {/* Other Pages End */}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
export default App;
