import Footer from "../footer/footer";
import Nav from "../nav/nav";
import Blog from "../blog/blog";
import Features from "../features/features";
import MoreProducts from "../more-products/more-products";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import VideoModal from "../video-modal/video-modal";
import "./j-series.scss";
import AnimatedText from "../animated-text/animated-text";

export default function JSeries() {

    const JSeries = {
        video: '/videos/J-series.mp4',
        mobileVideo: '/videos/J-series-mobile.mp4',
        modular1: '/products/j-series-assets/modular-1.png',
        modular2: '/products/j-series-assets/modular-2.png',
        diagram: '/products/j-series-assets/j-series-modular-diagram.png',
        topLeftConnector: '/products/j-series-assets/top-left-connector.png',
        topRightConnector: '/products/j-series-assets/top-right-connector.png',
        bottomLeftConnector: '/products/j-series-assets/bottom-left-connector.png',
        bottomMiddleConnector: '/products/j-series-assets/bottom-middle-connector.png',
        bottomRightConnector: '/products/j-series-assets/bottom-right-connector.png',
        cassettes: '/products/j-series-assets/cassettes.png',
        blogButton: '/products/blog-button.png',
        featureHeading: 'J-Series',
        divider: '/products/product-separator.png',
        datasheet: '/glc-resources/datasheets/J-Series.pdf',
        // whitepaper: '',
        whiteDownloadIcon: '/products/white-download-icon.svg',
        greenDownloadIcon: '/products/green-download-icon.svg',
        APDViewIcon: '/products/j-series-assets/j-series-action-btn.svg',
        options: {
            videoLink: '6nPJIZ1LC7Q',
            modalName: 'j-series-in-action'
        },
        features: [
            {
                icon: '/products/j-series-assets/feature-1.png',
                title: 'Modular<br /> Design',
                information: 'Modular design allows for easy installation, reconfiguration, and upgrades. The cassette is also interchangeable, allowing you to have any number of cassettes within the eight available slots.'
            },
            {
                icon: '/products/j-series-assets/feature-2.png',
                title: 'Multiple<br /> Mapping Options',
                information: 'The J-Series cassette is designed to support up to 64 fibers in a compact 1RU form factor. It offers multiple mapping options including Type A and Type B, which can be combined in the same frame.'
            },
            {
                icon: '/products/j-series-assets/feature-3.png',
                title: 'Wide<br /> Range of Support',
                information: 'The J-Series supports bandwidths of 40G, 100G, and 400G and is available in both single-mode and multi-mode fiber options, OS2, OM4, and MT Elite MPO Ferrules. This allows it to support a wide range of applications and help to future-proof your network.'
            },
            {
                icon: '/products/j-series-assets/feature-4.png',
                title: 'Easy<br /> Identification',
                information: 'Color coded fiber ports, each corresponding to the modality of the J-Series cassette, allow for easy identification.'
            },
            {
                icon: '/products/j-series-assets/feature-5.png',
                title: 'Industry<br /> Compliant',
                information: 'The J-Series cassette is fully compliant with industry standards such as Optical Fiber Non-conductive Plenum (OFNP) and RoHS, with industry standards, ensuring compatibility with existing fiber infrastructure.'
            },
            {
                icon: '/products/j-series-assets/feature-6.png',
                title: 'Compact<br /> and Reliable',
                information: 'With MPO8-to-4LC duplex modular cassettes that can fit in one frame, the J-Series cassette is ideal for space-constrained deployments.'
            },

        ],
        products: [
            {
                image: '/products/more-products/sensus.png',
                titleGreenPart: 'Sensus™ - ',
                titleWhitePart: 'Premium Fiber Patch Panel',
                information: "Big data is growing, people and their devices are becoming more connected, and data centers require more and more cable connections. We must control them more effectively! Green Lambda's Sensus™ steps in to help with that. Using intelligent cables and managed with APD software, it solves key physical layer challenges such as visibility and central management.",
                url: '/products/sensus',
            },
            {
                image: '/products/more-products/opx.png',
                titleGreenPart: 'OPX<sup class="registered-R">®</sup> -',
                titleWhitePart: 'Optical Path Exchange',
                information: 'A scalable and protocol agnostic software defined cross connect platform that makes your data center management intelligent and hassle free. The OPX® product series allows for automated patching, physical fiber connectivity mesh, network tapping providing network-wide visibility and eliminating signal strength loss.',
                url: '/products/opx',
            },
            {
                image: '/products/more-products/apd.png',
                titleGreenPart: 'APD - ',
                titleWhitePart: 'AllPath<sup class="registered-R">®</sup> Director',
                information: 'A centralized orchestration system called AllPath® Director offers abstracted network control that may be extended to hyperscale data centers. By extending it to layer one, APD makes it possible to manage and create dynamic physical infrastructure while also enabling software-controlled moves, adds, and changes.',
                url: '/products/apd',
            },
            {
                image: '/products/more-products/cable-and-clips.png',
                titleGreenPart: 'Fiber Optic',
                titleWhitePart: 'Cables',
                information: 'High quality multi-strand fiber cables and ICID® (Intelligent Connection Identification) clips that ensure efficient data transmission and ensure all data center communication needs. Capable of handling high-speed 10G, 25G, 40G, or 100G applications. These are available in Single or Multimode with A, B, or C polarity and UL-rated Plenum, Riser, or LSZH jackets.',
                url: '/products/fiber-optic-cables',
            },
        ]
    }

    return (
        <>
            <div>
                <Helmet>
                    <title>J Series | Green Lambda Corporation</title>
                    <meta name="description" content="Revamp fiber management with Green Lambda's J-Series. Compact 1RU cassettes with multiple mappings and easy installation. Efficient, reliable, modular!" />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="j-series-wrapper">
                {/* Nav Section Start */}
                <Nav />
                <div className="hero">
                    <div className="hero-content">
                        <video autoPlay playsInline loop muted className="j-series-video">
                            <source src={window.innerWidth < 600 ? JSeries.mobileVideo : JSeries.video} type="video/mp4"></source>
                        </video>
                    </div>
                </div>
                {/* Nav Section End */}

                {/* Product Header Section Start */}
                <div className="product-header text-white bg-blue">
                    <div className="container">
                        <div class="col-12 text-center">
                            <h2 className="nexa-bold"><AnimatedText>The ultimate solution for <span className="text-primary-green">high-density fiber</span> management in <span className="text-primary-green"> data centers </span> and other mission-critical <span className="text-primary-green">environments.</span> </AnimatedText></h2>
                        </div>
                        <div class="col-12 text-center mt-5">
                            <AnimatedText>

                                <Link data-bs-target={'#' + JSeries.options.modalName} data-bs-toggle="modal" className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2 me-4 action-btn"> <img class="me-2" src={JSeries.APDViewIcon} alt="APD-360-view-icon" />J-Series in Action</Link>
                                <Link to={JSeries.datasheet} target="_blank" className="btn secondary-btn dmsans-regular text-green border-secondary-color mt-2"><i className="fa-solid fa-arrow-down me-2"></i>DATASHEET</Link>
                            </AnimatedText>
                        </div>
                    </div>
                </div>
                {/* Product Header Section End */}
                {/* Product Info Section Start */}
                <div className="bg-white">
                    <div className="container">
                        <div className="product-info bg-blue">
                            <div className="row">
                                <div className="offset-lg-1 col-lg-11">
                                    <img src={JSeries.modular1}></img>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="offset-lg-1 col-lg-6 text-white">
                                    <h3 className="nexa-bold"><AnimatedText><span className="text-primary-green">J-Series</span> is Modular</AnimatedText></h3>
                                    <h4 className="mt-4 dmsans-regular modular-explanation nexa-bold">
                                        <AnimatedText>J-Series is a <span className="text-primary-green">modular cassette</span> based system with a maximum capacity of 8 cassettes, each cassette being a separate module with its own configuration. J-Series configurations can be <span className="text-primary-green">MM, SM, Type A and Type B</span> in the J-Series frame. The cassette is also interchangeable, allowing you to have any number of cassettes within the eight available slots. This allows for <span className="text-primary-green">easy and efficient</span> management of the network.</AnimatedText>
                                    </h4>
                                </div>
                                <div className="col-lg-5 my-auto">
                                    <img className="w-100 modular-image" src={JSeries.modular2}></img>
                                </div>
                            </div>
                        </div>
                        <div className="j-series-info-section-2 section-background">
                            <div class="row">
                                <div className="offset-lg-1 col-lg-10 text-center">
                                    <h4 className="mt-4 nexa-bold modular-explanation-2"><AnimatedText>The J-Series cassette offers an efficient and flexible way to manage your fiber infrastructure and support the growing demands of your network. It is designed to support up to <span className="text-primary-green">64 fibers in a compact 1RU form factor</span> and offers multiple mapping options including <span className="text-primary-green">Type A</span> and <span className="text-primary-green">Type B,</span> which can be combined in the  same frame, providing a high-density and flexible fiber management solution. With  <span className="text-primary-green">MPO8-to-4LC duplex modular cassettes</span> that can fit in one frame, the J-Series cassette is ideal for <span className="text-primary-green">space-constrained</span> deployments.</AnimatedText></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Product Info Section End */}

                <div className="bg-blue">
                    <div className="container">
                        {/* Product Diagram Section Start */}
                        <div className="text-center text-white j-series-diagram-heading">
                            <h2 className="nexa-bold"><AnimatedText>The <span className="text-primary-green">J-Series </span>Cassette</AnimatedText></h2>
                        </div>

                        <div className="row">
                            <div className="offset-lg-1 col-lg-10">
                                <div className="row mb-4 align-items-end">

                                    <img className="w-100" src={JSeries.diagram}></img>
                                </div>

                            </div>
                        </div>

                        <div className="diagram-info-section">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <h4 className="nexa-bold text-white modular-explanation"><AnimatedText>The Green Lambda J-Series <span className="text-primary-green">modular breakout</span> cassette is the perfect choice for <span className="text-primary-green">high-density and flexible fiber management</span> in data centers and other mission-critical environments. With its <span className="text-primary-green">modular design, multiple mapping and support for a wide range</span> of bandwidths and fiber types, it offers a reliable, efficient, and flexible solution for your fiber infrastructure needs.</AnimatedText></h4>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12">
                                    <img className="w-100" src={JSeries.cassettes}></img>
                                </div>
                            </div>
                        </div>
                        {/* Product Diagram Section End */}

                        {/* Blog Section Start */}
                        <Blog />
                        {/* Blog Section End */}
                    </div>
                    {/* Features Section Start */}
                    <Features features={JSeries.features} featureHeading={JSeries.featureHeading} />
                    {/* Features Section End */}

                    <div className="container">
                        {/* Other Products Header Section Start */}
                        <div className="d-flex product-info-header justify-content-center flex-column align-items-center text-center">
                            <img src="/products/more-product-header.png"></img>
                            <h2 className="nexa-bold">
                                <span className="text-primary-green">High Density</span>
                                <div>Fiber Management</div>
                                <span className="text-primary-green">in Data Centers</span>
                            </h2>
                            {/* <AnimatedText>
                                <Link className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color view-apd-btn mt-5" data-bs-target={'#' + JSeries.options.modalName} data-bs-toggle="modal">
                                    <img class="me-2" src={JSeries.APDViewIcon} alt="APD-360-view-icon" />J-Series in Action
                                </Link>
                            </AnimatedText> */}
                            <VideoModal options={JSeries.options} />
                        </div>
                        <img className="w-100" src={JSeries.divider} />
                        {/* Other Products Header Section Start */}

                        {/* More Products Section Start */}
                        <MoreProducts products={JSeries.products} />
                        {/* More Product Section End */}
                    </div>
                </div>

                {/* Footer Section Start */}
                <Footer />
                {/* Footer Section End */}
            </div>
        </>
    );
}