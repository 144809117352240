import "./footer.scss";
import { constants } from "../../constants";
import { Link } from "react-router-dom";
import AnimatedText from "../animated-text/animated-text";

export default function Footer() {
    const footer = {
        contactLink: '/contact-us',
        requestDemoLink: '/contact-us/#request-demo',
        privacyPolicyLink: '/privacy-policy',
        EULALink: '/eula',
        sitemapLink: '/sitemap',
        demoImage: '/backgrounds/demo-image.png',
        productsLinks: [
            {
                title: 'All Products',
                url: '/all-products',

            },
            {
                title: 'AllPath® Director',
                url: '/products/apd',
            },
            {
                title: 'Fiber Optic Cables',
                url: '/products/fiber-optic-cables',
            },
            {
                title: 'J-Series',
                url: '/products/j-series',
            },
            {
                title: 'OPX® (Optical Path Exchange)',
                url: '/products/opx',
            },
            {
                title: 'Sensus™',
                url: '/products/sensus',
            }
        ],
        companyLinks: [
            {
                title: 'About Us',
                url: '/about-us',
                openInNewTab: false
            },
            {
                title: 'Documentation Portal',
                url: 'https://docs.greenlambda.com/',
                openInNewTab: true
            },
            {
                title: 'Life at Green Lambda',
                url: '/glc-team',
                openInNewTab: false
            },
            {
                title: 'All Resources',
                url: '/resources',
                openInNewTab: false
            },
            // {
            //     title: 'Support',
            //     url: '/glc-support',
            // },
            {
                title: 'Contact Us',
                url: '/contact-us',
                openInNewTab: false
            }
            // {
            //     title: 'Blog',
            //     url: '',
            // },
        ],
        solutionLinks: [
            {
                title: 'Our Solutions',
                url: '/all-solutions',
            },
            {
                title: 'Automated Documentation',
                url: '/solutions/automated-documentation',
            },
            {
                title: 'Capacity Management',
                url: '/solutions/capacity-management',
            },
            {
                title: 'Data Center Colocation',
                url: '/solutions/datacenter-colocation',
            },
            {
                title: 'Layer One Diagnostics',
                url: '/solutions/layer-one-diagnostics',
            },
            {
                title: 'Physical Layer Security',
                url: '/solutions/physical-layer-security',
            },
            {
                title: 'Universal Breakout',
                url: '/solutions/universal-breakout',
            },
        ]
    }
    return (
        <>
            <div className="bg-white">
                {/* GLC  Request Demo Section */}
                <div className="glc-request-demo container">
                    <div className="request-demo-wrapper bg-purple">
                        <div className="row">
                            <div className="col-6 d-flex justify-content-center">
                                <img src={footer.demoImage} />
                            </div>
                            <div className="col-6 d-flex flex-column justify-content-center">
                                <h2 className="poppins-semi-bold text-white">Let’s Get Started?</h2>
                                <p className="dmsans-regular text-white">
                                    Experience our products firsthand to revolutionize your data centers today
                                </p>
                                <div className="d-flex btn-flex-container">
                                    <Link to={footer.requestDemoLink} className="btn primary-btn bg-primary-color text-white border-primary-color">
                                        Explore Solutions
                                    </Link>
                                    <Link to={footer.requestDemoLink} className="btn secondary-btn secondary-light-border text-white border-primary-color">
                                        Request a demo
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* GLC Request Demo Section End */}
            </div>
            {/* GLC Footer Section */}
            <footer className="bg-pink section-padding text-black" >
                {/*<div className="footer-left-gradient">*/}



                <div className="footer">
                    <img
                        src={constants.images.logos.FMLogo}
                        target="blank"
                        alt="logo"
                        className="fm-logo" />
                    <p className="dmsans-regular">
                        Fiber Mountain is a provider of world class tracking, monitoring, and automation infrastructure for hyperscale, government, and enterprise networks. We bring innovation and intelligence to the physical layer through our hardware and software products.</p>
                    <div className="d-flex justify-content-space-around footer-links">
                        <div>
                            <a className="link" href="/all-products">Products</a>
                        </div>
                        <div>
                            <a className="link" href="">Solutions</a>
                        </div>
                        <div>
                            <a className="link"  href="/about-us">About Us</a>
                        </div>
                        <div>
                            <a className="link"  href="">Events</a>
                        </div>


                    </div>
                </div>
                <div className="container">
                <hr className="hr-footer"></hr>
                </div>
                <div className="legal col-12 container">
                    <div className="col-md-4">
                        <p className="footer-text">Fiber Mountain © 2024. All rights reserved.</p>
                    </div>
                    <div id="social" className="social-links col-md-4 text-center mb-3">
                        <span className="footer-text">Follow us at: </span>
                        <a className="facebookBtn smGlobalBtn" target="_blank"
                            href="https://www.facebook.com/fibermountaininc/"><img
                                src="/icons/social-icons/facebook.png" /></a>
                        <a className="facebookBtn smGlobalBtn" target="_blank"
                            href="https://www.facebook.com/profile.php?id=100085866826868"><img
                                src="/icons/social-icons/instagram.png" /></a>
                        <a className="linkedinBtn smGlobalBtn" target="_blank"
                            href="https://www.linkedin.com/company/green-lambda-corporation"><img
                                src="/icons/social-icons/linked-in.png" /></a>
                        <a className="youtubeBtn smGlobalBtn" target="_blank"
                            href="https://www.youtube.com/channel/UCpiIIq-ywzbbFM0c8qkqJMg"><img
                                src="/icons/social-icons/youtube.png" /></a>


                    </div>

                    <div className="col-md-4 text-end mb-3">
                        <div>
                            <a className="link" target="_blank" href="">Privacy Policy</a>
                            <span className="mx-2">|</span>
                            <a className="link" target="_blank" href="">EULA</a>
                        </div>
                    </div>


                </div>
            </footer>
        </>
    );
}