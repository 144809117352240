import Footer from "../footer/footer";
import HelpCenter from "../help-center/help-center";
import Nav from "../nav/nav";
import "./about-us.scss";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from 'react-helmet';
import LocationsModal from "../locations-modal/locations-modal";
import AnimatedText from "../animated-text/animated-text";

export default function AboutUs() {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 4,
        className: "slider variable-width",
        vertical: false,
        responsive: [
            {
                breakpoint: 600, // Breakpoint for mobile devices
                settings: 'unslick', // Remove the slider on mobile
            },
        ],
    }
    const AboutUs = {
        bulb: '/company/about-us/bulb.png',
        worldMap: '/company/contact-us/world-map.png',
        glcStory: '/company/about-us/glc-story.png',
        glcVision: '/company/about-us/glc-vision.png',
        glcMission: '/company/about-us/glc-mission.png',
        glcMonitoring: '/company/about-us/monitoring.svg',
        glcSecurity: '/company/about-us/security.svg',
        glcControl: '/company/about-us/control.svg',
        allProductsURL: '/all-products',
        options: {
            modalName: 'view-addresses',
            title: '<span class="text-primary-green">Our</span> Locations'
        },
        glcLeadership: [
            {
                image: '/company/about-us/raza-headshot.png',
                name: 'M.H <span class="text-primary-green">Raza</span>',
                position: 'CEO & President',
            },
            {
                image: '/company/about-us/arsalan-headshot.png',
                name: 'Ahmed <span class="text-primary-green">Arsalan</span>',
                position: 'Chief Technology Officer',
            },
            {
                image: '/company/about-us/asang-headshot.png',
                name: '<span class="text-primary-green">Asang</span> Cooc',
                position: 'Director, Sales',
            },
            {
                image: '/company/about-us/hose-headshot.png',
                name: '<span class="text-primary-green">Jose</span> Pats',
                position: 'Director, Product',
            }
        ],
        glcProducts: [
            {
                icon: '/company/about-us/apd-icon.png',
                greenPartName: 'APD -',
                whitePartName: 'AllPath<sup class="registered-R">®</sup> Director',
                info: 'Integrating software with hardware to digitally transform the physical layer.',
                link: '/products/apd'

            },
            {
                icon: '/company/about-us/sensus-icon.png',
                greenPartName: 'Sensus™',
                whitePartName: 'Premium Fiber Patch Panel',
                info: 'Transforming network infrastructure with a 100% passive intelligent patch panel.',
                link: '/products/sensus'

            },
            {
                icon: '/company/about-us/j-series.png',
                greenPartName: 'J-Series',
                whitePartName: 'Breakout Panels',
                info: 'The ultimate solution for high-density fiber management in data centers and other mission-critical environments.',
                link: '/products/j-series'

            },
        ],
        glcAwards: [
            {
                logo: '/company/about-us/logo-1.svg',
                heading: 'BICSI Winter<br/> Conference',
                info: '1st Place in Connectors &<br/> Connectorization, 2018: Sensus™'
            },
            {
                logo: '/company/about-us/logo-2.svg',
                heading: 'Network Innovation<br/> Awards',
                info: 'SearchNetworking.com’s Network<br/> Innovation Awards – Software Defined<br/> Networking, 2016'
            },
            {
                logo: '/company/about-us/logo-3.svg',
                heading: 'ITEXPO, Anaheim<br/> Best of Show Award',
                info: 'Best Enterprise Solution, 2015:<br/> Optical Path Exchange'
            },
            {
                logo: '/company/about-us/logo-4.svg',
                heading: 'Best of Interop<br/> Awards',
                info: 'Best of Interop Finalist,<br/> Data Center Category, 2015'
            },
            {
                logo: '/company/about-us/logo-3.svg',
                heading: 'ITEXPO Miami Best<br/> of Show Award',
                info: 'Most Innovative<br/> Product, 2015'
            },
            {
                logo: '/company/about-us/logo-5.svg',
                heading: 'InfoTech Data Center<br/> Excellence Award',
                info: 'InfoTech Spotlight Data Center<br/> Excellence Award, 2014'
            },

        ]
    }
    return (
        <>
            <div>
                <Helmet>
                    <title>About Us | Green Lambda Corporation</title>
                    <meta name="description" content="All about Green Lambda's history and ideology. Get to know more about the GLC culture and corporate code of conduct." />
                    {/* Add more meta tags as needed */}
                </Helmet>
            </div>
            <div className="about-us-wrapper bg-blue text-white">
                <div className="hero position-relative">
                    {/* Nav Start */}
                    <Nav />
                    {/* Nav End */}
                    <div className="hero-content hero-bg bg-blue d-flex justify-content-center align-items-center flex-column position-relative">
                        <div className="hero-heading-bg"><AnimatedText> <h1 className="nexa-light text-white text-center">About<div className="nexa-bold text-gradient">Green Lambda™</div></h1> </AnimatedText></div>
                    </div>
                </div>
                <div className="container">
                    <div className="glc-story bg-blue d-flex justify-content-center align-items-center flex-column">
                        <h2 className="nexa-bold text-white text-story-heading"><AnimatedText>Our Innovations <span className="text-primary-green">Digitalize</span> the Physical Layer and Bring it to Life</AnimatedText></h2>
                        <h5 className="text-white dmsans-regular justify-content-center text-story about-info">
                            <AnimatedText>We bring a brand-new perspective to managing critical networks with only one objective – incorporating security and intelligence into the physical layer to reduce the time, effort and cost of managing data centers.</AnimatedText>
                        </h5>
                    </div>
                    <div className="glc-vision">

                        <div className="row">
                            <div className="col-lg-6 col-sm-6">
                                <AnimatedText> <div className="label text-center"><p className="text-white dmsans-regular">Vision</p></div></AnimatedText>
                                <h3 className="nexa-bold"><AnimatedText>We Envision to <span className="text-primary-green">Drive Change</span> Through the Power of <span className="text-primary-green">Security and Intelligence</span></AnimatedText></h3>
                                <h5 className="dmsans-regular">
                                    <AnimatedText>Unlike other areas of the network, the physical layer is still using technologies from the 90s. While switches and routers are loaded with sensors and software, the physical layer cable and patch panels are still in the dark ages. Green Lambda’s vision is to change that. It is high time we digitalize something as important as the physical medium on which entire networks run. Our vision for the physical layer is digital. We have re-imagined how the physical layer is tracked, monitored and controlled and have introduced a vast array of hardware and software products that enable enterprises to automate their network infrastructure and make it secure.</AnimatedText>
                                </h5>
                                <AnimatedText>
                                    <Link
                                        to="/contact-us"
                                        className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color">
                                        <span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                            GET IN TOUCH
                                        </span>
                                    </Link>
                                </AnimatedText>
                            </div>
                            <div className="col-lg-6 col-sm-6 vision-image-wrapper">
                                <img className="my-auto" src={AboutUs.glcVision} />
                            </div>

                        </div>

                    </div>
                    <div className="glc-mission">
                        <div className="row">
                            <div className="col-lg-6 col-sm-6 mission-image-wrapper">
                                <img className="my-auto" src={AboutUs.glcMission} />

                            </div>
                            <div className="col-lg-6 col-sm-6">
                                <AnimatedText><div className="label text-center"><p className="text-white dmsans-regular">Mission</p></div></AnimatedText>
                                <h3 className="nexa-bold"><AnimatedText>Empowering Enterprises to Harness the <span className="text-primary-green">Transformative Power of Software</span> in the Physical Layer</AnimatedText></h3>
                                <h5 className="dmsans-regular">
                                    <AnimatedText> As the data center experts, our mission is to digitalize every physical layer asset (cables, ports, racks, etc.) through sensors and software and empower you to monitor, control and secure it just like any other network equipment (switches, routers, firewall, etc.) Our innovation is in recognizing the disconnect between digitalization and the physical layer and applying our decades of experience in the field of physical layer infrastructure and networking to this problem. The result is a simple solution that allows companies to have a pulse on their physical layer infrastructure.</AnimatedText>
                                </h5>
                                <AnimatedText>
                                    <Link
                                        to="/contact-us"
                                        className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color">
                                        <span data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                                            GET IN TOUCH
                                        </span>
                                    </Link>
                                </AnimatedText>
                            </div>
                        </div>

                    </div>
                    <div className="glc-visibility">
                        <h3 className="text-center nexa-bold"><AnimatedText><span className="text-primary-green">Real-time Visibility</span> into the <span className="text-primary-green">Physical Layer Infrastructure</span></AnimatedText></h3>
                        <div className="row">
                            <div className="col-lg-10 icons-wrapper">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-4 d-flex justify-content-start">
                                        <div className="text-center">
                                            <img className="icon" src={AboutUs.glcMonitoring} />
                                            <h4 className="nexa-bold"><AnimatedText>Monitoring</AnimatedText></h4>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-4 text-center">
                                        <img className="icon" src={AboutUs.glcSecurity} />
                                        <h4 className="nexa-bold"><AnimatedText>Security</AnimatedText></h4>

                                    </div>
                                    <div className="col-lg-4 col-sm-4 d-flex justify-content-end">
                                        <div className=" text-center">
                                            <img className="icon" src={AboutUs.glcControl} />
                                            <h4 className="nexa-bold"><AnimatedText>Control</AnimatedText></h4>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                    {/* <div className="glc-leadership bg-blue">
                    <div className="text-center">
                        <h3 className="text-leadership-stroke nexa-bold">Behind the Brand</h3>
                        <h2 className="text-white nexa-bold brand-margin">The Executive <span className="text-primary-green">Team</span></h2>
                        <h5 className="text-white dmsans-regular text-behind-brand about-info">Behind Green Lambda™ is a team of seasoned professionals with a long history of excellence in the networking industry. Our leadership principles revolve around earning customer trust, taking ownership and finding ways to simplify complex problems through innovative solutions.</h5>
                    </div>
                    <div className="leadership-container row">
                        {AboutUs.glcLeadership.map((value, index) => {
                            return (
                                <div className="leader-element col-lg-3">
                                    <img className="w-100" src={value.image} />
                                    <div className="leader-info">
                                        <h3 className="nexa-bold mt-3" dangerouslySetInnerHTML={{ __html: value.name }}></h3>
                                        <h5 className="nexa-light text-white">{value.position}</h5>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div> */}
                    <div className="glc-awards">
                        <h2 className="nexa-bold text-center"><AnimatedText><span className="text-primary-green">Awards</span> & Recognition</AnimatedText></h2>
                        <div className="slick-width">
                            <Slider {...settings}>

                                {AboutUs.glcAwards.map((value, index) => {
                                    return (
                                        <div className="slide-item" key={index}>
                                            <div className="award-element text-center">
                                                <img className="mx-auto logo" src={value.logo} />
                                                <h4 className="nexa-bold" dangerouslySetInnerHTML={{ __html: value.heading }}></h4>
                                                <p className="dmsans-regular" dangerouslySetInnerHTML={{ __html: value.info }}></p>

                                            </div>

                                        </div>
                                    )
                                })}
                            </Slider>

                        </div>


                    </div>

                    <div className="glc-products justify-content-center d-flex flex-column text-center
                ">
                        <span className="glc-products-stroke nexa-bold">GLC PRODUCTS</span>
                        <h2 className="glc-products-heading nexa-bold text-white"><AnimatedText><span className="text-primary-green">Experience </span>it For Yourself</AnimatedText></h2>
                        <h5 className="text-white dmsans-regular text-product"><AnimatedText>View our best-selling products <Link className="text-url" to={AboutUs.allProductsURL}>here</Link> and schedule a demo to see them in action. Discover endless possibilities of how to transform your network infrastructure with us.</AnimatedText></h5>
                        <div className="row text-center w-100 mb-5 products-container">
                            {AboutUs.glcProducts.map((value, index) => {
                                return (
                                    <div className="col-lg-4 col-sm-4">
                                        <div className="product-element text-center">
                                            <img src={value.icon} />
                                            <h4 className="text-white nexa-bold text-element"><AnimatedText> <span className="text-primary-green">{value.greenPartName}</span> <span dangerouslySetInnerHTML={{ __html: value.whitePartName }}></span></AnimatedText></h4>
                                            <p className="text-white product-text dmsans-regular"><AnimatedText>{value.info}</AnimatedText></p>
                                            <Link to={value.link} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2 btn-read-more">LEARN MORE</Link>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                </div>
                <div className="glc-corporate-citizenship">
                    <h2 className="nexa-bold"><AnimatedText>Social <span className="text-primary-green">Responsibility</span></AnimatedText></h2>
                    <h5 className="dmsans-regular"><AnimatedText><span className="text-primary-green">Corporate citizenship</span> is at the heart of everything we do. We consolidate our practices, partnerships and products around a single mission - fostering <span className="text-primary-green">sustainability</span> at scale. </AnimatedText></h5>


                </div>
                <div className="container">
                    <HelpCenter />
                </div>
                <div>
                    <div className="container text-center">
                        <h2 class="text-white text-center nexa-bold"><AnimatedText>Our <span class="text-primary-green">Locations</span></AnimatedText></h2>
                        <AnimatedText>
                            <Link className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color view-apd-btn mt-5" data-bs-target={'#' + AboutUs.options.modalName} data-bs-toggle="modal">
                                View Addresses
                            </Link>
                        </AnimatedText>
                        <LocationsModal options={AboutUs.options} />
                    </div>
                    <img className="w-100 world-map-mobile" src={AboutUs.worldMap} />
                </div>



                {/* Footer Start */}
                <Footer />
                {/* Footer End */}
            </div >
        </>
    );
}