import "./more-products.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import AnimatedText from "../animated-text/animated-text";

export default function MoreProducts({ products }) {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    }
    return (
        <>
            <div className="more-products-container">
                <AnimatedText>
                    <h3 className="text-white more-products-heading text-center nexa-bold">Explore More Products</h3>
                </AnimatedText>
                <Slider {...settings}>
                    {products.map((value, index) => {
                        return (
                            <div className="slide-item">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12 my-auto">
                                        <h4>
                                            <AnimatedText>
                                                <span className="text-primary-green nexa-bold"><span dangerouslySetInnerHTML={{ __html: value.titleGreenPart }}></span> </span>
                                                <span className="text-white dmsans-regular nexa-bold" dangerouslySetInnerHTML={{ __html: value.titleWhitePart }}></span>
                                            </AnimatedText>
                                        </h4>
                                        <AnimatedText>
                                            <p className="text-white" dangerouslySetInnerHTML={{ __html: value.information }}>
                                            </p>
                                        </AnimatedText>
                                        <AnimatedText>
                                            <Link to={value.url} className="btn primary-btn dmsans-regular bg-primary-color text-white border-primary-color mt-2">LEARN MORE</Link>
                                        </AnimatedText>
                                    </div>
                                    <div className="col-lg-6 col-sm-12 my-auto position-relative">
                                        <div className="more-products-gradient"></div>
                                        <img className="mx-auto" src={value.image}></img>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </>
    );
}