import "./help-center.scss";
import AnimatedText from "../animated-text/animated-text";

export default function HelpCenter() {
    const HelpCenter = {
        helpBorder: '/company/about-us/help-border.png',
        glcHelpCenter: [
            {
                icon: '/company/about-us/portal-icon.png',
                title: 'Support',
                info: '<a class="text-url" href="mailto:support@fibermountain.com">support@fibermountain.com</a>',
                infoOptional: '',
                gridClass: 'col-lg-4 col-sm-4'

            },
            {
                icon: '/company/about-us/phone-icon.png',
                title: 'Phone',
                info: '1-800-480-9424',
                infoOptional: '1-405-443-3686',
                gridClass: 'col-lg-3 col-sm-3'

            },
            {
                icon: '/company/about-us/email-icon.png',
                title: 'Sales',
                info: '<a class="text-url" href="mailto:sales@fibermountain.com">sales@fibermountain.com</a>',
                gridClass: 'col-lg-5 col-sm-5'

            }
        ],
    }
    return (
    <>
        <div className="glc-help-center">
        <AnimatedText><h2 className="text-black text-center poppins-regular">Help <span className="text-primary-red poppins-regular">Center</span></h2></AnimatedText>
                    <div className="row text-center w-100 mt-5">
                        {HelpCenter.glcHelpCenter.map((value, index) => {
                            return (
                                <div className={value.gridClass}>
                                    <div className="help-element text-left">
                                        <img className="mb-3" src={value.icon} />
                                        <h4 className="poppins-semi-bold help-title text-primary-red"><AnimatedText>{value.title}</AnimatedText></h4>
                                        <AnimatedText><h5 className="text-black dmsans-regular help-info" dangerouslySetInnerHTML={{__html: value.info}}></h5></AnimatedText>
                                        <h5 className="text-black dmsans-regular help-info"><AnimatedText>{value.infoOptional}</AnimatedText></h5>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                </div>
    </>
    );
}